import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";
import { useKeycloak } from "@react-keycloak/web";
import { Button, Col, Dropdown, DropdownButton, Form, Offcanvas, Row, Table } from "react-bootstrap";
import { DataTable } from "simple-datatables";

const Rules = () => {

    const { keycloak, initialized } = useKeycloak();
    let { security_group_id } = useParams();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [enablePort, setEnablePort] = useState(true);
    const [enableRangePorts, setEnableRangePorts] = useState(false);
    const [enableAllPorts, setEnableAllPorts] = useState(false);

    /**const [data, setData] = useState(null);*/

    /**
     * Security Groups States
     */
    const [rules, setRules] = useState([]);
    const [securityGroupRule, setSecurityGroupRule] = useState({ protocol: 'tcp', description: '', direction: 'ingress', port_range_min: 0, port_range_max: 0, ethertype: 'ipv4', security_group_id: security_group_id });

    useEffect(() => {
        console.log(initialized);
        getSecurityGroupsRules();
    }, []);

    const handleSecurityGroupRules = (event) => {
        if (event.target.name === 'open_port') {
            if (event.target.value === 'port') {
                setEnablePort(true);
                setEnableAllPorts(false);
                setEnableRangePorts(false);
            } else if (event.target.value === 'port_range') {
                setEnableRangePorts(true);
                setEnableAllPorts(false);
                setEnablePort(false);
            } else if (event.target.value === 'all_ports') {
                setEnableAllPorts(true);
                setEnableRangePorts(false);
                setEnablePort(false);
            }
        }
        const fields = { protocol: securityGroupRule.protocol, description: securityGroupRule.description, direction: securityGroupRule.direction, port_range_min: securityGroupRule.port_range_min, port_range_max: securityGroupRule.port_range_max, ethertype: securityGroupRule.ethertype, security_group_id: security_group_id };
        fields[event.target.name] = event.target.value;
        setSecurityGroupRule(fields);
        /**console.log(fields);*/
    }

    const onCreateSecurityGroupRules = async (e) => {
        e.preventDefault();
        if (enableAllPorts === true) {
            delete securityGroupRule.port_range_min;
            delete securityGroupRule.port_range_max;
            delete securityGroupRule.open_port;
        } else if (enablePort === true) {
            let port_range = securityGroupRule.port_range_min
            securityGroupRule.port_range_min = port_range;
            securityGroupRule.port_range_max = port_range;
        }
        const toastId = toast.loading("Creating security group rule please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/security-group-rules`, { security_group_rule: securityGroupRule }, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            toast.update(toastId, { render: "Security Group Rule successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleClose();
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getSecurityGroupsRules();
        });
    }

    const onDeleteSecurityGroupRule = async (security_group_rule_id) => {
        if (window.confirm('This security group rule will be permanently deleted')) {
            const toastId = toast.loading("Deleting security group rule please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/security-group-rules/${security_group_rule_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Security group rule successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getSecurityGroupsRules();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    const getSecurityGroupsRules = async () => { // obtain all keypairs registered in the server
        await axios.get(`${getHostNameServer()}/security-groups/${security_group_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            ///console.log(res.data.security_group.security_group_rules);
            if (res.data.security_group.security_group_rules.length > 0) {
                setRules(res.data.security_group.security_group_rules);
            }
        }).catch((err) => {
            toast.error(`We are having problems}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <h3>Security Group Rules</h3>
                </Col>
                <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3">
                        Add Rule
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm" id="table-instances" style={{ fontSize: '14px' }}>
                <thead>
                    <tr>
                        <th>...</th>
                        <th>Direction</th>
                        <th>Ether Type</th>
                        <th>IP Protocol</th>
                        <th>Port Range</th>
                        <th>Remote IP Prefix</th>
                        <th>Remote Security Group</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rules.map((security_group_rule, _) => {
                        return (
                            <tr key={security_group_rule.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{security_group_rule.direction}</td>
                                <td>{security_group_rule.ethertype}</td>
                                <td>{security_group_rule.protocol === null ? 'Any' : security_group_rule.protocol.toUpperCase()}</td>
                                <td>{security_group_rule.port_range_min === 22 ? security_group_rule.port_range_min + " (SSH)" : security_group_rule.port_range_min}</td>
                                <td>{security_group_rule.remote_ip_prefix === null ? '' : security_group_rule.remote_ip_prefix}</td>
                                <td>{security_group_rule.remote_address_group_id === null ? '-' : security_group_rule.remote_address_group_id}</td>
                                <td>{security_group_rule.description === null ? '-' : security_group_rule.description}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Dropdown.Item href="#" onClick={() => onDeleteSecurityGroupRule(security_group_rule.id)}>Delete Rule</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        New Security Group Rule
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h5><strong>Description:</strong></h5>
                    <span style={{ fontSize: '15px' }}>Rules define which traffic is allowed to instances assigned to the security group. A security group rule consists of three main parts:</span>
                    <br />
                    <span style={{ fontSize: '15px' }}><strong>Rule:</strong> You can specify the desired rule template or use custom rules, the options are Custom TCP Rule, Custom UDP Rule, or Custom ICMP Rule.</span>
                    <br />
                    <span style={{ fontSize: '15px' }}><strong>Open Port/Port Range:</strong> For TCP and UDP rules you may choose to open either a single port or a range of ports. Selecting the "Port Range" option will provide you with space to provide both the starting and ending ports for the range. For ICMP rules you instead specify an ICMP type and code in the spaces provided.</span>
                    <br />
                    <span style={{ fontSize: '15px' }}><strong>Remote:</strong> You must specify the source of the traffic to be allowed via this rule. You may do so either in the form of an IP address block (CIDR) or via a source group (Security Group). Selecting a security group as the source will allow any other instance in that security group access to any other instance via this rule.</span>
                    <Form onSubmit={onCreateSecurityGroupRules}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Rule <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="protocol" value={securityGroupRule.protocol} onChange={handleSecurityGroupRules} required>
                                {/* <option>Select</option> */}
                                <option value="tcp">Custom TCP Rule</option>
                                <option value="udp">Custom UDP Rule</option>
                                <option value="icmp">Custom ICMP Rule</option>
                                {/* <option value="custom">Other Protocol</option>
                                <option value="icmp">ICMP</option>
                                <option value="tcp">TCP</option>
                                <option value="udp">UDP</option>
                                <option value="dns">DNS</option>
                                <option value="http">HTTP</option>
                                <option value="https">HTTPS</option>
                                <option value="imap">IMAP</option>
                                <option value="imaps">IMAPS</option>
                                <option value="ldap">LDAP</option>
                                <option value="ms_sql">MS SQL</option>
                                <option value="mysql">MYSQL</option>
                                <option value="pop3">POP3</option>
                                <option value="pop3s">POP3S</option>
                                <option value="rdp">RDP</option> 
                                <option value="smtp">SMTP</option>
                                <option value="smtps">SMTPS</option> */}
                                <option value="tcp">SSH</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" name="description" size="sm" rows={1} placeholder="Description" onChange={handleSecurityGroupRules} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Direction</Form.Label>
                            <Form.Select size="sm" name="direction" defaultValue={"ingress"} onChange={handleSecurityGroupRules} required>
                                <option value="ingress">Ingress</option>
                                <option value="egress">Egress</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Open Port <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="open_port" defaultValue={"port"} onChange={handleSecurityGroupRules} required>
                                <option value="port">Port</option>
                                <option value="port_range">Port Range</option>
                                <option value="all_ports">All Ports</option>
                            </Form.Select>
                        </Form.Group>
                        {enablePort && (
                            <>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Port <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="number" size="sm" name="port_range_min" onChange={handleSecurityGroupRules} required />
                                </Form.Group>
                            </>
                        )}
                        {enableRangePorts && (
                            <>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>From Port <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="number" size="sm" name="port_range_min" value={securityGroupRule.port_range_min} onChange={handleSecurityGroupRules} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>To Port <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="number" size="sm" name="port_range_max" value={securityGroupRule.port_range_max} onChange={handleSecurityGroupRules} required />
                                </Form.Group>
                            </>
                        )}

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Remote <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="protocol" onChange={handleSecurityGroupRules} defaultValue={"cidr"} required>
                                <option value="cidr">CIDR</option>
                                <option value="sg">Security Group</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="remote_ip_prefix">
                            <Form.Label>CIDR <span className="text-danger">*</span></Form.Label>
                            <Form.Control size="sm" name="remote_ip_prefix" value={"0.0.0.0/0"} onChange={handleSecurityGroupRules} required />
                        </Form.Group>

                        <Button variant="primary" size="sm" type="submit">
                            Add
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );

}
export default Rules;