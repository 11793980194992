import React, { useContext, useEffect, useMemo, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getHostNameServer } from "../../services/helpers/config";
import { StatusContext } from "../../contexts/status-context";

const Init = () => {

    const [status, setStatus] = useContext(StatusContext);

    const isPassed = useMemo(() => {
        return status;
    }, [status]);

    const { keycloak, initialized } = useKeycloak();
    const [launching, setLaunching] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        verify();
        console.log(initialized);
    }, []);

    const verify = async () => {
        setVerifying(true);
        /** load data bookings from sever */
        await axios.post(`${getHostNameServer()}/identity/verify`, {}, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200 && res.data.ready === true) {
                setStatus({verified: true, passed: true});
                navigate('/overview');
            } else {
                setStatus({verified: false, passed: false});
            }
        }).catch((err) => {
            setStatus({verified: false, passed: false});
        }).finally(() => {
            setVerifying(false);
        });
    }

    const onLaunch = async () => {
        setLaunching(true);
        /** load data bookings from sever */
        await axios.post(`${getHostNameServer()}/identity/init/cloud`, {
            userName: keycloak.idTokenParsed.preferred_username,
            email: keycloak.idTokenParsed.email
        }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                setStatus({verified: true, passed: true});
                navigate('/overview');
                setLaunching(false);
            }
        }).catch((err) => {
            console.log(err.response);
        }).finally(() => {
            setLaunching(false);
        });
    }

    if (verifying === true) {
        return (
            <>
                <Container fluid>
                    <div className="text-center">
                        <div style={{ display: 'flex', justifyContent: 'center', minHeight: '600px', alignItems: 'center' }}>
                            <h2 style={{ textAlign: 'center' }}>
                                <Spinner animation="border" variant="primary" />{" "}
                                Loading
                            </h2>
                        </div>
                    </div>
                </Container>
            </>
        );
    }
    return (
        <>
            <Container fluid>
                <div className="text-center">
                    {launching && (
                        <div style={{ display: 'flex', justifyContent: 'center', minHeight: '600px', alignItems: 'center' }}>
                            <h2 style={{ textAlign: 'center' }}>
                                <Spinner animation="border" variant="primary" />{" "}
                                Starting Cloud instance...
                            </h2>
                        </div>
                    )}
                    {(!launching && !isPassed.verified) && (
                        <>
                        <button style={{ marginTop: '15%' }} className="btn btn-primary btn-lg" onClick={onLaunch}>Init Cloud</button>
                        </>
                    )}
                </div>
            </Container>
        </>
    );
}
export default Init;
