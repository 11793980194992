import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useRef, useState } from "react";
import { DropdownButton, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getHostNameServer } from "../../../services/helpers/config";
import axios from "axios";
import { toast } from "react-toastify";

const InstanceDetails = () => {

    const { keycloak, initialized } = useKeycloak();
    let { id } = useParams();
    const [interfaceAttachments, setInterfaceAttachments] = useState([]);
    const [loadingInterfaceAttachments, setLoadingInterfaceAttachments] = useState(false);
    const [loadingConsole, setLoadingConsole] = useState(false);
    const [urlAccess, setUrlAccess] = useState('');
    const tableRef = useRef();

    useEffect(() => {
        console.log(initialized);
        getConsole();
        getInterfaces();
    }, []);

    /**
     * get All Instance Interface attachments
     */
    const getInterfaces = async () => {
        setLoadingInterfaceAttachments(true);
        await axios.get(`${getHostNameServer()}/${id}/os-interfaces`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.interfaceAttachments.length > 0) {
                setInterfaceAttachments(res.data.interfaceAttachments);
                setLoadingInterfaceAttachments(false);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Interface Attachments: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    /**
     * get interfaceAttachment Fixed IPs
     * @param {*} interfaceAttachment 
     * @returns 
     */
    const getFixedIPs = (interfaceAttachment = {}) => { // obtain all servers registered in the server
        const allIps = [];
        Object.keys(interfaceAttachment.fixed_ips).forEach(key => {
            const ips = interfaceAttachment.fixed_ips.map(address => address.ip_address);
            allIps.push(...ips);
        });
        return allIps.join(', ');
    }

    /**
     * get Console
     * 
     */
    const getConsole = async () => {
        setLoadingConsole(true);
        await axios.post(`${getHostNameServer()}/instances/${id}/console`, { "os-getSPICEConsole": { "type": "spice-html5" } }, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.console) {
                const urlParams = new URLSearchParams(new URL(res.data.console.url).search);
                setUrlAccess(urlParams.get('token'));
                setLoadingConsole(false);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Console: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    return (
        <>
            <Tabs
                defaultActiveKey="interfaces"
                id="uncontrolled-tab-manages"
                className="mb-3"
            >
                <Tab eventKey="overview" title="Overview">
                    <h3>Overview</h3>
                </Tab>
                <Tab eventKey="interfaces" title="Interfaces">
                    <Table striped bordered responsive hover size="sm" ref={tableRef} id="table-instances" style={{ fontSize: '14px' }}>
                        <thead className="text-secondary">
                            <tr>
                                {/* <th>...</th> */}
                                <th>Name</th>
                                <th>Network</th>
                                <th>Fixed IPs</th>
                                <th>MAC Address</th>
                                <th>Status</th>
                                <th>Admin State</th>
                                <th>Operations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {interfaceAttachments.length === 0 && (
                                <tr>
                                    <td className="text-center" colSpan={7}>
                                        {loadingInterfaceAttachments ?
                                            <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                                <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                                    <Spinner animation="grow" size="sm" variant="info" />{" "}
                                                    Loading...
                                                </h5>
                                            </div>
                                            : <p>You do not have any interfaces</p>}
                                    </td>
                                </tr>
                            )}
                            {interfaceAttachments.map((interfaceAttachment, _) => {
                                return (
                                    <tr key={interfaceAttachment.port_id}>
                                        {/* <td><Form.Check></Form.Check></td> */}
                                        <td>{interfaceAttachment.port_id}</td>
                                        <td>{interfaceAttachment.net_id}</td>
                                        <td>{getFixedIPs(interfaceAttachment)}</td>
                                        <td>{interfaceAttachment.mac_addr}</td>
                                        <td>{interfaceAttachment.port_state}</td>
                                        <td>{interfaceAttachment.port_state}</td>
                                        <td>
                                            <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} style={{ position: 'static' }} id="actions" size="sm" title="Actions">
                                                {/* <Dropdown.Item href="#" onClick={() => onRebootInstance(instance.id)}>Reboot instance</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => onDeleteInstance(instance.id)}>Delete Instance</Dropdown.Item> */}
                                            </DropdownButton>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="console" title="Console">
                    <h1>Console</h1>
                    {loadingConsole ? 'Loading...' : <a href={`https://console.idtolu.net/spice_auto.html?token=${urlAccess}`} target="_blank">Open Console in new Tab</a>}
                </Tab>
            </Tabs>
            {/* <ToastContainer /> */}
        </>
    );

}
export default InstanceDetails;