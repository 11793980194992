import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { getHostNameEvents, getHostNameServer } from "../../services/helpers/config";
import axios from "axios";
import bytes from "bytes";
import moment from "moment-timezone";

const Overview = () => {

    const { keycloak, initialized } = useKeycloak();
    const [eventSource, setEventSource] = useState(null);
    const [events, setEvents] = useState([]);

    const [overViewCompute, setOverWiewCompute] = useState({});
    const [overViewVolumes, setOverWiewVolumes] = useState({});
    const [overViewNetworks, setOverWiewNetworks] = useState({});

    const timeZone = moment.tz.guess();

    

    useEffect(() => {
        getQuotaSetsCompute();
        getQuotaSetsVolumes();
        getQuotaSetsNetworks();
        getEvents();
        console.log(initialized, eventSource);
    }, []);

    useEffect(() => {

        // se hace una instancia de EventSource de javascript
        const newEvent = new EventSource(`${getHostNameEvents()}/events/instances/${keycloak.idTokenParsed.sub}`, { withCredentials: true });

        // carga la instancia dentro de la variable socket declarada del hook useState
        setEventSource(newEvent);

        // evento que se genera al iniciar la conexxion satisfactoriamente
        newEvent.onopen = (event) => {
            console.log('Connected', event.isTrusted);
        }

        // evento que captura el mensaje recibido del servidor
        newEvent.onmessage = (message) => {
            if (message.isTrusted) {
                const event = JSON.parse(message.data);
                setEvents(prev => [event, ...prev]);
                console.log('Ready');
            }
        }

        // evento que captura el error
        newEvent.onerror = () => {
            console.log('Error Server');
        }

        return () => newEvent.close();
    }, [setEventSource]);

    const getEvents = async () => {
        await axios.get(`${getHostNameServer()}/events`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            setEvents(res.data);
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const getQuotaSetsCompute = async () => {
        await axios.get(`${getHostNameServer()}/quota-sets/compute`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            ///console.log(res.data.quota_set);
            if (res.status === 200) {
                setOverWiewCompute(res.data.quota_set);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const getQuotaSetsVolumes = async () => {
        await axios.get(`${getHostNameServer()}/quota-sets/volumes`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            ///console.log(res.data.quota_set);
            if (res.status === 200) {
                setOverWiewVolumes(res.data.quota_set);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const getQuotaSetsNetworks = async () => {
        await axios.get(`${getHostNameServer()}/quota-sets/networks`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            ///console.log(res.data.quota);
            if (res.status === 200) {
                setOverWiewNetworks(res.data.quota);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const fromMb = (overViewCompute) => {
        return bytes(overViewCompute.ram.in_use * 1024 * 1024);
    }

    const fromGb = (overViewVolumes) => {
        return bytes(overViewVolumes.gigabytes.in_use * 1024 * 1024 * 1024);
    }

    return (
        <>
            <div className="pagetitle">
                <h1>Overview</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active">Overview</li>
                    </ol>
                </nav>
            </div>
            <section className="section dashboard">
                <div className="row">

                    {/* <!-- Left side columns --> */}
                    <div className="col-lg-8">
                        <div className="row">

                            {/* <!-- CPU Card --> */}
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card sales-card">

                                    <div className="card-body">
                                        <h5 className="card-title">CPU <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-cpu"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(typeof overViewCompute.cores !== 'undefined') ? overViewCompute.cores.in_use : ''}</h6>
                                                <span className="text-success small pt-1 fw-bold">{(overViewCompute.cores) ? (parseFloat(overViewCompute.cores.in_use / overViewCompute.cores.limit).toFixed(2) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!-- RAM Card --> */}
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card revenue-card">

                                    <div className="card-body">
                                        <h5 className="card-title">RAM <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-memory"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(overViewCompute.ram) ? fromMb(overViewCompute) : ''}</h6>
                                                <span className="text-success small pt-1 fw-bold">{(overViewCompute.ram) ? (parseFloat(overViewCompute.ram.in_use / overViewCompute.ram.limit).toFixed(2) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!-- Instances Card --> */}
                            <div className="col-xxl-4 col-xl-12">

                                <div className="card info-card customers-card">

                                    <div className="card-body">
                                        <h5 className="card-title">Instances <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-hdd-stack-fill"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(overViewCompute.instances) ? overViewCompute.instances.in_use : ''}</h6>
                                                <span className="text-danger small pt-1 fw-bold">{(overViewCompute.instances) ? (parseFloat(overViewCompute.instances.in_use / overViewCompute.instances.limit) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="row">

                            {/* <!-- Volumes Card --> */}
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card sales-card">

                                    <div className="card-body">
                                        <h5 className="card-title">Volumes <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-device-hdd"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(typeof overViewVolumes.volumes !== 'undefined') ? overViewVolumes.volumes.in_use : ''}</h6>
                                                <span className="text-success small pt-1 fw-bold">{(typeof overViewVolumes.volumes !== 'undefined') ? (parseFloat(overViewVolumes.volumes.in_use / overViewVolumes.volumes.limit) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!-- Snapshots Card --> */}
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card revenue-card">

                                    <div className="card-body">
                                        <h5 className="card-title">Snapshots <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-device-hdd"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(typeof overViewVolumes.snapshots !== 'undefined') ? overViewVolumes.snapshots.in_use : ''}</h6>
                                                <span className="text-success small pt-1 fw-bold">{(typeof overViewVolumes.snapshots !== 'undefined') ? (parseFloat(overViewVolumes.snapshots.in_use / overViewVolumes.snapshots.limit) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!-- Storage Card --> */}
                            <div className="col-xxl-4 col-xl-12">

                                <div className="card info-card customers-card">

                                    <div className="card-body">
                                        <h5 className="card-title">Storage <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-device-hdd-fill"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(typeof overViewVolumes.gigabytes !== 'undefined') ? fromGb(overViewVolumes) : ''}</h6>
                                                <span className="text-danger small pt-1 fw-bold">{(typeof overViewVolumes.gigabytes !== 'undefined') ? (parseFloat(overViewVolumes.gigabytes.in_use / overViewVolumes.gigabytes.limit) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">

                            {/* <!-- CPU Card --> */}
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card sales-card">

                                    <div className="card-body">
                                        <h5 className="card-title">Floating IPs <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-hdd-network"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(typeof overViewNetworks.floatingip !== 'undefined') ? overViewNetworks.floatingip.used : ''}</h6>
                                                <span className="text-success small pt-1 fw-bold">{(typeof overViewNetworks.floatingip !== 'undefined') ? (parseFloat(overViewNetworks.floatingip.used / overViewNetworks.floatingip.limit) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!-- RAM Card --> */}
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card revenue-card">

                                    <div className="card-body">
                                        <h5 className="card-title">Security Groups <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-hdd-network-fill"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(typeof overViewNetworks.security_group !== 'undefined') ? overViewNetworks.security_group.used : ''}</h6>
                                                <span className="text-success small pt-1 fw-bold">{(typeof overViewNetworks.security_group !== 'undefined') ? (parseFloat(overViewNetworks.security_group.used / overViewNetworks.security_group.limit) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!-- Instances Card --> */}
                            <div className="col-xxl-4 col-xl-12">

                                <div className="card info-card customers-card">

                                    <div className="card-body">
                                        <h5 className="card-title">S.G Rules <span>| Live</span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-collection-fill"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{(typeof overViewNetworks.security_group_rule !== 'undefined') ? overViewNetworks.security_group_rule.used : ''}</h6>
                                                <span className="text-danger small pt-1 fw-bold">{(typeof overViewNetworks.security_group_rule !== 'undefined') ? (parseFloat(overViewNetworks.security_group_rule.used / overViewNetworks.security_group_rule.limit) * 100).toFixed(2) : ''}%</span> <span className="text-muted small pt-2 ps-1">used</span>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    {/* <!-- Right side columns --> */}
                    <div className="col-lg-4">

                        {/* <!-- Recent Activity --> */}
                        <div className="card">

                            <div className="card-body">
                                <h5 className="card-title">Welcome to IDTOLU Cloud <span></span></h5>

                                <div className="activity">

                                    {events.map((event, index, array) => {
                                        return (
                                            <div className="activity-item d-flex" key={event.id}>
                                                <div className="activite-label">{event.state === 'DELETED' ? moment(event.deletedAt).clone().tz(timeZone).fromNow() : moment(event.createdAt).clone().tz(timeZone).fromNow()}</div>
                                                <i className={`bi bi-circle-fill activity-badge text-${event.state === 'DELETED' ? 'danger' : 'success'} align-self-start`}></i>
                                                <div className="activity-content">
                                                    {event.message}
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {/**<div className="activity-item d-flex">
                                        <div className="activite-label">32 min</div>
                                        <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                                        <div className="activity-content">
                                            Quia quae rerum <a href="#" className="fw-bold text-dark">explicabo officiis</a> beatae
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">56 min</div>
                                        <i className='bi bi-circle-fill activity-badge text-danger align-self-start'></i>
                                        <div className="activity-content">
                                            Voluptatem blanditiis blanditiis eveniet
                                        </div>
                                    </div>

                                     <div className="activity-item d-flex">
                                        <div className="activite-label">2 hrs</div>
                                        <i className='bi bi-circle-fill activity-badge text-primary align-self-start'></i>
                                        <div className="activity-content">
                                            Voluptates corrupti molestias voluptatem
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">1 day</div>
                                        <i className='bi bi-circle-fill activity-badge text-info align-self-start'></i>
                                        <div className="activity-content">
                                            Tempore autem saepe <a href="#" className="fw-bold text-dark">occaecati voluptatem</a> tempore
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">2 days</div>
                                        <i className='bi bi-circle-fill activity-badge text-warning align-self-start'></i>
                                        <div className="activity-content">
                                            Est sit eum reiciendis exercitationem
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">4 weeks</div>
                                        <i className='bi bi-circle-fill activity-badge text-muted align-self-start'></i>
                                        <div className="activity-content">
                                            Dicta dolorem harum nulla eius. Ut quidem quidem sit quas
                                        </div>
                                    </div> */}

                                </div>

                            </div>
                        </div>

                        {/* <!-- Website Traffic --> */}
                        {/* <div className="card">
                            <div className="filter">
                                <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                    <li className="dropdown-header text-start">
                                        <h6>Filter</h6>
                                    </li>

                                    <li><a className="dropdown-item" href="#">Today</a></li>
                                    <li><a className="dropdown-item" href="#">This Month</a></li>
                                    <li><a className="dropdown-item" href="#">This Year</a></li>
                                </ul>
                            </div>

                            <div className="card-body pb-0">
                                <h5 className="card-title">Website Traffic <span>| Today</span></h5>

                                <div id="trafficChart" style={{ minHeight: '400px' }} className="echart"></div>
                            </div>
                        </div> */}

                    </div>

                </div>
            </section>
        </>
    )

}
export default Overview;