import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Offcanvas, Row, Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";
import moment from "moment";

const Floatingips = () => {

    const { keycloak, initialized } = useKeycloak();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [enableAdminStateUp, setEnableAdminStateUp] = useState(true);

    const [floatingIPs, setFloatingIPs] = useState([]);
    const [floatingIP, setFloatingIP] = useState({ floating_network_id: '', description: '', dns_domain: '', dns_name: '' });
    const [loadingFloatingIPs, setLoadingFloatingIPs] = useState(false);

    useEffect(() => {
        console.log(initialized);
        getFloatingIPs();
    }, []);

    const handleChangeFloatingIP = (event) => {
        const fields = { name: floatingIP.floating_network_id, description: floatingIP.description, dns_domain: floatingIP.dns_domain, dns_name: floatingIP.dns_name };
        fields[event.target.name] = event.target.value;
        setFloatingIP(fields);
        console.log(fields);
    }

    const onSubmitFloatingIP = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Creating Floating IP please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/floatingips`, { floatingip: floatingIP }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Floating IP successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            console.log(res.data);
            handleClose();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getFloatingIPs();
        });
        console.log(floatingIP);
    }

    const onDeleteFloatingIP = async (floatingip_id) => {
        if (window.confirm('This Floating IP will be permanently deleted')) {
            const toastId = toast.loading("Deleting Floating IP please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/floatingips/${floatingip_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Floating IP successfully deleted", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getFloatingIPs();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    const getFloatingIPs = () => {
        setFloatingIPs([]);
        setLoadingFloatingIPs(true);
        axios.get(`${getHostNameServer()}/floatingips`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                setLoadingFloatingIPs(false);
                ///console.log(res.data.floatingips);
                setFloatingIPs(res.data.floatingips);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <h3 className="floet-start">Floating IPs</h3>
                </Col>
                <Col>
                <div className="float-end">
                        <Button variant="outline-dark" onClick={() => getFloatingIPs()} size="sm" className="mb-3">
                            <i className="bi bi-arrow-clockwise"></i>
                        </Button>{' '}
                        <Button variant="outline-primary" onClick={handleShow} size="sm" className="mb-3" disabled={floatingIPs.length >= 1 ? true : false}>
                            Create floating IP
                        </Button>
                    </div>
                </Col>
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>IP Address</th>
                        <th>Description</th>
                        <th>DNS Name</th>
                        <th>DNS Domain</th>
                        <th>Mapped Fixed IP Address</th>
                        <th>Pool</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {floatingIPs.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={10}>
                                {loadingFloatingIPs ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any floating IP</p>}
                                <Button variant="outline-secondary" onClick={handleShow} size="sm" className="mb-3" disabled={loadingFloatingIPs}>
                                    Create floating IP
                                </Button>
                            </td>
                        </tr>
                    )}
                    {floatingIPs.map((floatingip, _) => {
                        return (
                            <tr key={floatingip.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{floatingip.floating_ip_address}</td>
                                <td>{floatingip.description}</td>
                                <td>{floatingip.dns_name}</td>
                                <td>{floatingip.dns_domain}</td>
                                <td>{floatingip.fixed_ip_address ? floatingip.fixed_ip_address : '-'}</td>
                                <td>{""}</td>
                                <td>{floatingip.status}</td>
                                <td>{moment(floatingip.created_at).fromNow()}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        {/** <Link className="dropdown-item" to={`/network/floatingips/${floatingip.id}`}>Manage Router</Link> */}
                                        <Dropdown.Item href="#" onClick={() => onDeleteFloatingIP(floatingip.id)}>Delete Floating IP</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><strong>Create New Floating IP</strong></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onSubmitFloatingIP}>
                        {/* <Form.Group className="mb-3" controlId="floating_network_id">
                            <Form.Label>Pool <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="floating_network_id" value={floatingIP.floating_network_id} onChange={handleChangeFloatingIP} required>
                                <option value="">Select</option>
                                <option value="">External Network</option>
                            </Form.Select>
                        </Form.Group> */}

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" name="description" onChange={handleChangeFloatingIP} rows={4} placeholder="" value={floatingIP.description} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>DNS Domain <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" name="dns_domain" size="sm" placeholder="Enter name" value={floatingIP.dns_domain} onChange={handleChangeFloatingIP} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>DNS Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" name="dns_name" size="sm" placeholder="Enter name" value={floatingIP.dns_name} onChange={handleChangeFloatingIP} />
                        </Form.Group>

                        <Button variant="primary" size="sm" type="submit">
                            Create Floating IP
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );

}
export default Floatingips;