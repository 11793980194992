import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Offcanvas, OverlayTrigger, Popover, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";

const Volumes = () => {

    const { keycloak, initialized } = useKeycloak();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [sourceImage, setSourceImage] = useState(false);
    const [images, setImages] = useState([]);
    const [imageRef, setImageRef] = useState('');

    const [volumes, setVolumes] = useState([]);
    const [tempVolume, setTempVolume] = useState({});
    const [volume, setVolume] = useState({ name: '', description: 'NO Description', source_volid: '', imageRef: '', volume_type: '__DEFAULT__', size: 1, availability_zone: 'nova', consistencygroup_id: '' });
    const [creatingVolume, setCreatingVolume] = useState(false);
    const [loadingVolumes, setLoadingVolumes] = useState(false);

    useEffect(() => {
        console.log(initialized);
        getImages();
        getVolumes();
    }, []);

    const handleSourceVolume = (event) => {
        if (event.target.name === 'source_volid' && event.target.value === 'imageRef') {
            setSourceImage(true);
        } else if (event.target.name === 'source_volid' && event.target.value === '') {
            setSourceImage(false);
        }
    }

    const handleChangeVolume = (event) => {
        const fields = { name: volume.name, description: volume.description, source_volid: volume.source_volid, imageRef: volume.imageRef, volume_type: volume.volume_type, size: volume.size, availability_zone: volume.availability_zone, consistencygroup_id: volume.consistencygroup_id };
        fields[event.target.name] = event.target.value;
        setVolume(fields);
    }

    const onSubmitVolume = async (e) => {
        e.preventDefault();
        setCreatingVolume(true);
        if (sourceImage) {
            volume.imageRef = imageRef;
        } else {
            volume.imageRef = '';
            delete volume.imageRef;
        }
        const toastId = toast.loading("Creating Volume please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/volumes`, { volume: volume }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Volume successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
            setCreatingVolume(false);
            getVolumes();
            handleClose();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    const onDeleteVolume = async (volume_id) => {
        if (window.confirm('This volume will be deleted.\n\nWe remind you that this action will permanently delete the volume and the data will be lost.')) {
            const toastId = toast.loading("Deleting Volume please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/volumes/${volume_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                getVolumes();
                toast.update(toastId, { render: "Volume successfully deleted", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    const getImages = async () => { // obtain all images
        await axios.get(`${getHostNameServer()}/images`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.images.length > 0) {
                setImages(res.data.images);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the images: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    const getVolumes = () => {
        setLoadingVolumes(true);
        axios.get(`${getHostNameServer()}/volumes/detail`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                ///console.log(res.data.volumes);
                setVolumes(res.data.volumes);
                setLoadingVolumes(false);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const getVolume = (volume_id) => {
        axios.get(`${getHostNameServer()}/volumes/${volume_id}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                console.log(res.data.volume);
                setTempVolume(res.data.volume);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const getVolumeAttacments = (instance_id) => {
        axios.get(`${getHostNameServer()}/volumes/${instance_id}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                console.log(res.data.volume);
                setTempVolume(res.data.volume);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const getAttachments = (attachments) => {
        let devices = attachments.map(obj => obj.device);
        return devices.join(',');
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Volume details</Popover.Header>
            <Popover.Body>
                <table className="table table-bordered">
                    <tbody>
                        <>
                            <tr>
                                <th>Name: </th>
                                <td>{tempVolume.name}</td>
                            </tr>
                            <tr>
                                <th>ID: </th>
                                <td>{tempVolume.id}</td>
                            </tr>
                            <tr>
                                <th>Description: </th>
                                <td>{tempVolume.description}</td>
                            </tr>
                            {/* <tr>
                                <th>Attachments: </th>
                                <td>{JSON.stringify(tempVolume.attachments)}</td>
                            </tr> */}
                        </>
                    </tbody>
                </table>
            </Popover.Body>
        </Popover>
    );
    return (
        <>
            <Row>
                <Col>
                    <h3 className="float-start">Volumes</h3>
                </Col>
                <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3">
                        Create Volume
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Size</th>
                        <th>Status</th>
                        <th>Group</th>
                        <th>Type</th>
                        <th>Attached To</th>
                        <th>Availability Zone</th>
                        <th>Bootable</th>
                        <th>Encrypted</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {volumes.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={12}>
                                {loadingVolumes ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any volumes</p>}
                            </td>
                        </tr>
                    )}
                    {volumes.map((volume, _) => {
                        return (
                            <tr key={volume.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{volume.name}</td>
                                <td>{volume.description}</td>
                                <td>{volume.size}GiB</td>
                                <td>{volume.status}</td>
                                <td>{volume.group_id ? volume.group_id : '-'}</td>
                                <td>{volume.volume_type}</td>
                                <td>
                                    {getAttachments(volume.attachments)}&nbsp;&nbsp;
                                    <OverlayTrigger trigger={"click"} placement="right" overlay={popover}>
                                        <Link onClick={() => getVolume(volume.id)} variant="white" placeholder="click me" size="sm">Detail</Link>
                                    </OverlayTrigger>
                                </td>
                                <td>{volume.availability_zone}</td>
                                <td>{volume.booteable === 'false' ? 'NO' : 'YES'}</td>
                                <td>{volume.encrypted ? 'YES' : 'NO'}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Link className="dropdown-item" to={`/network/floatingips/${volume.id}`}>Manage Volume</Link>
                                        <Dropdown.Item href="#" onClick={() => onDeleteVolume(volume.id)}>Delete Volume</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h4><strong>Create Volume</strong></h4>
                        <span>Volumes are block devices that can be attached to instances.</span>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onSubmitVolume}>
                        <Form.Group className="mb-3" controlId="floating_network_id">
                            <Form.Label>Volume Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control size="sm" name="name" onChange={handleChangeVolume} value={volume.name} pattern="[a-zA-Z_]*" maxLength={20} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control size="sm" as="textarea" name="description" onChange={handleChangeVolume} rows={2} value={volume.description} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Volume Source <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="source_volid" onChange={handleSourceVolume}>
                                <option value="">No source, empty volume</option>
                                <option value="imageRef">Image</option>
                            </Form.Select>
                        </Form.Group>

                        {sourceImage && (
                            <Form.Group className="mb-3" controlId="imageRef">
                                <Form.Label>Image S.0 <span className="text-danger">*</span></Form.Label>
                                <Form.Select name="imageRef" onChange={handleChangeVolume} size="sm" required >
                                    <option value="">Select image</option>
                                    {images.map((image, _) => {
                                        return (<option value={image.id} key={image.id}>{image.name}</option>)
                                    })}
                                </Form.Select>
                            </Form.Group>
                        )}

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Type <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="volume_type" value={volume.volume_type} onChange={handleChangeVolume} required>
                                <option value="__DEFAULT__">__DEFAULT__</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="floating_network_id">
                            <Form.Label>Size (GiB) <span className="text-danger">*</span></Form.Label>
                            <Form.Control size="sm" type="number" name="size" onChange={handleChangeVolume} placeholder="" value={volume.size} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Availability Zone <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="availability_zone" value={volume.availability_zone} onChange={handleChangeVolume} required>
                                <option value="nova">nova</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Group</Form.Label>
                            <Form.Select size="sm" name="consistencygroup_id" value={volume.consistencygroup_id} onChange={handleChangeVolume} >
                                <option value="null">No group</option>
                            </Form.Select>
                        </Form.Group>

                        <Button variant="primary" size="sm" type="submit" disabled={creatingVolume}>
                            Create Volume
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );

}
export default Volumes;