const Footer = () => {

    return (
        <>
            {/* <!-- ======= Footer ======= --> */}
            <footer id="footer" className="footer mt-5">
                <div className="copyright">
                    &copy; Copyright <strong><span>IDTOLU Lab</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Adapted by <a href="#">IDTOLU Lab</a>
                </div>
            </footer>
        </>
    );
}
export default Footer;