import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {

    return (
        <>
            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className="nav-link " to="/">
                        <i className="bi bi-grid"></i>
                        <span>Overview</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"></i><span>Compute</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/compute/instances">
                                <i className="bi bi-circle"></i><span>Instances</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/compute/instance-types">
                                <i className="bi bi-circle"></i><span>Instance types</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/compute/images">
                                <i className="bi bi-circle"></i><span>Images</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/compute/keypairs">
                                <i className="bi bi-circle"></i><span>Key Pairs</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-device-hdd"></i><span>Volume</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/volume/volumes">
                                <i className="bi bi-circle"></i><span>Volumes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/volume/snapshots">
                                <i className="bi bi-circle"></i><span>Snapshots</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/volume/groups">
                                <i className="bi bi-circle"></i><span>Groups</span>
                            </Link>
                        </li> */}
                    </ul>
                </li>

                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-hdd-network"></i><span>Network & Security</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="tables-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/network/networks">
                                <i className="bi bi-circle"></i><span>Networks</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/network/routers">
                                <i className="bi bi-circle"></i><span>Routers</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/network/security-groups">
                                <i className="bi bi-circle"></i><span>Security Groups</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/network/floating-ips">
                                <i className="bi bi-circle"></i><span>Floating IPs</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                {/* <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#charts-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-bar-chart"></i><span>Docs</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="charts-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <a href="charts-chartjs.html">
                                <i className="bi bi-circle"></i><span>Chart.js</span>
                            </a>
                        </li>
                        <li>
                            <a href="charts-apexcharts.html">
                                <i className="bi bi-circle"></i><span>ApexCharts</span>
                            </a>
                        </li>
                        <li>
                            <a href="charts-echarts.html">
                                <i className="bi bi-circle"></i><span>ECharts</span>
                            </a>
                        </li>
                    </ul>
                </li> */}

                {/* <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-gem"></i><span>Icons</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="icons-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <a href="icons-bootstrap.html">
                                <i className="bi bi-circle"></i><span>Bootstrap Icons</span>
                            </a>
                        </li>
                        <li>
                            <a href="icons-remix.html">
                                <i className="bi bi-circle"></i><span>Remix Icons</span>
                            </a>
                        </li>
                        <li>
                            <a href="icons-boxicons.html">
                                <i className="bi bi-circle"></i><span>Boxicons</span>
                            </a>
                        </li>
                    </ul>
                </li> */}

            </ul>
        </>
    );
}
export default Sidebar;