import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Offcanvas, Row, Spinner, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";

const Images = () => {

    const { keycloak, initialized } = useKeycloak();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [images, setImages] = useState([]);
    const [image, setImage] = useState({ name: '' });
    const [loadingImages, setLoadingImages] = useState(false);

    useEffect(() => {
        console.log(initialized);
        getImages();
    }, []);

    const handleChangeImage = (event) => {
        const fields = { name: image.name };
        fields[event.target.name] = event.target.value;
        setImage(fields);
        console.log(fields);
    }

    const onSubmitImage = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Creating Image please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/images`, { snapshot: image }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Image successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            console.log(res.data);
            handleClose();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getImages();
        });
    }

    const onDeleteSnapshot = async (snapshot_id) => {
        if (window.confirm('This Image will be permanently deleted')) {
            const toastId = toast.loading("Deleting Snapshot please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/images/${snapshot_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Image successfully deleted", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getImages();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    const getImages = () => {
        setLoadingImages(true);
        axios.get(`${getHostNameServer()}/images/owner`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                setImages(res.data.images);
                setLoadingImages(false);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <h1 className="floet-start">Images</h1>
                </Col>
                {/* <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3">
                        Create Image
                    </Button>
                </Col> */}
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Visibility</th>
                        <th>Protected</th>
                        <th>Disk Format</th>
                        <th>Size</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {images.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={9}>
                                {loadingImages ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any images</p>}
                            </td>
                        </tr>
                    )}
                    {images.map((image, _) => {
                        return (
                            <tr key={image.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{image.name}</td>
                                <td>{image.image_type}</td>
                                <td>{image.status}</td>
                                <td>{image.visibility}</td>
                                <td>{image.protected ? 'YES' : 'NO'}</td>
                                <td>{image.disk_format}</td>
                                <td>{(image.size / 1073741824).toFixed(2)}GB</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        {/* <Link className="dropdown-item" to={`/network/floatingips/${snapshot.id}`}>Manage Volume</Link> */}
                                        <Dropdown.Item href="#" onClick={() => onDeleteSnapshot(image.id)}>Delete Snapshot</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h4><strong>Create Volume</strong></h4>
                        <span>Volumes are block devices that can be attached to instances.</span>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onSubmitImage}>
                        <Form.Group className="mb-3" controlId="floating_network_id">
                            <Form.Label>Volume Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control size="sm" name="name" onChange={handleChangeImage} value={image.name} />
                        </Form.Group>

                        <Button variant="primary" size="sm" type="submit">
                            Create Snapshot
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );

}
export default Images;