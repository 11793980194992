import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Badge, Button, Col, Dropdown, DropdownButton, Form, ListGroup, Modal, Offcanvas, OverlayTrigger, Popover, ProgressBar, Row, Spinner, Table } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import "simple-datatables/dist/css/style.css";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";
import moment from "moment-timezone";
///import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

const Instances = () => {

    const { keycloak, initialized } = useKeycloak();
    const timeZone = moment.tz.guess();
    ///const [socket, setSocket] = useState(null);
    const [assigned, setAssigned] = useState([]);
    const [unAssigned, setUnAssigned] = useState([]);
    const [instanceID, setInstanceID] = useState('');
    const [instanceFlavor, setInstanceFlavor] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getImages();
        getFlavors();
        getAvailabilityZones();
        getKeyPairs();
        getNetworks();
        getSecurityGroups();
    }

    const [showModalEditSecurityGroup, setShowModalEditSecurityGroup] = useState(false);
    const handleCloseModalEditSecurityGroup = () => {
        setShowModalEditSecurityGroup(false);
        setInstanceID('');
    }
    const handleShowModalEditSecurityGroup = (instance_id, security_groups = []) => {
        setInstanceID(instance_id);
        setShowModalEditSecurityGroup(true);
        let common = security_groups.map(object => object.name);
        let uncommon = [];
        for (const element of securityGroups) {
            if (common.includes(element.name)) {
                common.push(element.name);
            } else {
                uncommon.push(element.name);
            }
        }
        common = common.filter((value, indice) => {
            return common.indexOf(value) === indice;
        });
        setAssigned(common);
        setUnAssigned(uncommon);
    }

    const [volumes, setVolumes] = useState([]);
    const [attachVolume, setAttachVolume] = useState({ volumeId: '' });
    const [showModalAttachVolume, setShowModalAttachVolume] = useState(false);
    const handleCloseModalAttachVolume = () => {
        setShowModalAttachVolume(false);
        setInstanceID('');
    }
    const handleShowModalAttachVolume = (instance_id) => {
        getVolumes();
        setInstanceID(instance_id);
        setShowModalAttachVolume(true);
    }

    const [detachVolume, setDetachVolume] = useState({ volume_id: '' });
    const [showModalDetachVolume, setShowModalDetachVolume] = useState(false);
    const handleCloseModalDetachVolume = () => {
        setShowModalDetachVolume(false);
        setInstanceID('');
    }
    const handleShowModalDetachVolume = (instance_id) => {
        getVolumes();
        setInstanceID(instance_id);
        setShowModalDetachVolume(true);
    }

    const [snapshot, setSnapshot] = useState({ name: '' });
    const [showModalSnapshot, setShowModalSnapshot] = useState(false);
    const handleCloseModalSnapshot = () => {
        setShowModalSnapshot(false);
        setInstanceID('');
    }
    const handleShowModalSnapshot = (instance_id) => {
        getVolumes();
        setInstanceID(instance_id);
        setShowModalSnapshot(true);
    }

    const [resizing, setResizing] = useState(false);
    const [flavor, setFlavor] = useState({});
    const [resizeInstance, setResizeInstance] = useState({ flavorRef: '' });
    const [showModalResizeInstance, setShowModalResizeInstance] = useState(false);
    const handleCloseModalResizeInstance = () => {
        setShowModalResizeInstance(false);
        setInstanceID('');
        setFlavor({});
    }
    const handleShowModalResizeInstance = (instance_id, flavorID) => {
        setFlavors([]);
        getFlavors();
        getFlavor(flavorID);
        setInstanceID(instance_id);
        setShowModalResizeInstance(true);
    }

    const [attaching, setAttaching] = useState(false);
    const [attachInterface, setAttachInterface] = useState({ ip_address: '', net_id: '' });
    const [showModalAttachInterface, setShowModalAttachInterface] = useState(false);
    const handleCloseModalAttachInterface = () => {
        setShowModalAttachInterface(false);
        setInstanceID('');
    }
    const handleShowModalAttachInterface = (instance_id) => {
        setInstanceID(instance_id);
        setShowModalAttachInterface(true);
        getNetworks();
    }

    const [detaching, setDetaching] = useState(false);
    const [detachInterface, setDetachInterface] = useState({ port_id: '' });
    const [showModalDetachInterface, setShowModalDetachInterface] = useState(false);
    const handleCloseModalDetachInterface = () => {
        setShowModalDetachInterface(false);
        setInstanceID('');
        getPorts();
    }
    const handleShowModalDetachInterface = (instance_id) => {
        setInstanceID(instance_id);
        setShowModalDetachInterface(true);
        getPorts();
    }

    const [instances, setInstances] = useState([]);
    const [instance, setInstance] = useState({ name: '', description: 'No Description', availability_zone: 'nova', imageRef: '', flavorRef: '', networks: '', security_groups: '', key_name: '', public_key: '', password: '' });
    const [enablePassword, setEnablePassword] = useState(false);
    ///const [password, setPassword] = useState("");
    const [loadingInstances, setLoadingInstances] = useState(false);
    const [launchingInstance, setLaunchingInstance] = useState(false);

    const [images, setImages] = useState([]);
    const [flavors, setFlavors] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [ports, setPorts] = useState([]);
    const [availabilityZones, setAvalilabilityZones] = useState([]);
    const [securityGroups, setSecurityGroups] = useState([]);
    const [keyPairs, setKeyPairs] = useState([]);
    const [floatingIP, setFloatingIP] = useState({ description: '', dns_domain: '', dns_name: '' });

    const tableRef = useRef();

    useEffect(() => {
        console.log(initialized);
        getSecurityGroups();
        getInstances();
    }, []);

    /**
     * Dinamic JSON State Create Instance
     * @param {*} event 
     */
    const handleLaunchInstance = (event) => {
        const fields = { name: instance.name, description: instance.description, availability_zone: instance.availability_zone, imageRef: instance.imageRef, flavorRef: instance.flavorRef, networks: instance.networks, security_groups: instance.security_groups, key_name: instance.key_name, public_key: instance.public_key, password: instance.password };
        fields[event.target.name] = event.target.value;
        setInstance(fields);
        ///console.log(fields);
    }

    /**
     * State enable Password
     * @param {*} event 
     */
    const handleEnablePassword = (event) => {
        if (event.target.name === 'enablePassword') {
            setEnablePassword(event.target.checked);
        }
    }

    /**
     * Dinamic JSON State Attach Volume Instance
     * @param {*} event 
     */
    const handleAttachVolume = (event) => {
        const fields = { volumeId: attachVolume.volumeId };
        fields[event.target.name] = event.target.value;
        setAttachVolume(fields);
    }

    /**
     * Dinamic JSON State Detach Volume Instance
     * @param {*} event 
     */
    const handleDetachVolume = (event) => {
        const fields = { volume_id: detachVolume.volume_id };
        fields[event.target.name] = event.target.value;
        setDetachVolume(fields);
    }

    /**
     * Dinamic JSON State Snapshot From Instance
     * @param {*} event 
     */
    const handleSnapshot = (event) => {
        const fields = { name: snapshot.name };
        fields[event.target.name] = event.target.value;
        setSnapshot(fields);
    }

    /**
     * Dinamic JSON State Resize Instance
     * @param {*} event 
     */
    const handleResizeIntance = (event) => {
        const fields = { flavorRef: resizeInstance.flavorRef };
        fields[event.target.name] = event.target.value;
        setResizeInstance(fields);
    }

    /**
     * Dinamic JSON State Attach Interface
     * @param {*} event 
     */
    const handleAttachInterface = (event) => {
        const fields = { ip_address: attachInterface.ip_address, net_id: attachInterface.net_id };
        fields[event.target.name] = event.target.value;
        setAttachInterface(fields);
    }

    /**
     * Dinamic JSON State Detach Interface
     * @param {*} event 
     */
    const handleDetachInterface = (event) => {
        const fields = { ip_address: detachInterface.port_id };
        fields[event.target.name] = event.target.value;
        setDetachInterface(fields);
    }

    /**
     * Create instance
     * @param {*} e 
     */
    const onSubmitInstance = async (e) => {
        e.preventDefault();
        setLaunchingInstance(true);
        if (enablePassword) {
            instance.password = instance.password !== '' ? instance.password : '';
        } else {
            instance.password = "";
        }
        instance.networks = [{ uuid: instance.networks }];
        instance.security_groups = [{ name: instance.security_groups }];

        const toastId = toast.loading("Launching Instance please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances`, instance, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Scheduled instance creation", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleClose();
            setLaunchingInstance(false);
            getInstances();
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            setLaunchingInstance(false);
        });
    }

    /**
     * Delete an instance
     * @param {*} instance_id 
     */
    const onDeleteInstance = async (instance_id) => {
        if (window.confirm('This Instance will be permanently deleted')) {
            const toastId = toast.loading("Deleting Instance please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/instances/${instance_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "The Instance will soon be removed...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 3000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getInstances();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    /**
     * get All intances
     */
    const getInstances = async () => {
        setInstances([]);
        setLoadingInstances(true);
        await axios.get(`${getHostNameServer()}/instances`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                setLoadingInstances(false);
                setInstances(res.data.servers);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    /**
     * get Instance By Id
     */
    const getInstance = async () => {
        await axios.get(`${getHostNameServer()}/instances/${instanceID}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                console.log(res.data);
                handleCloseModalEditSecurityGroup();
                handleShowModalEditSecurityGroup(res.data.server.id, res.data.server.security_groups);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    /**
     * get Server IPs
     * @param {*} server 
     * @returns 
     */
    const getIps = (server = {}) => { // obtain all servers registered in the server
        const allIps = [];
        Object.keys(server.addresses).forEach(key => {
            const ips = server.addresses[key].map(address => address.addr);
            allIps.push(...ips);
        });
        return allIps.join(', ');
        ///console.log(allIps.join(', '));
    }

    /**
     * get IP Ports
     * @param {*} port 
     * @returns 
     */
    const getIpPort = (port = {}) => { // obtain all IP ports registered in the server
        const allIps = [];
        const ips = port.fixed_ips.map(address => address.ip_address);
        allIps.push(...ips);
        return allIps.join(', ');
    }

    const getStates = (server) => {
        let state = '';
        switch (server['OS-EXT-STS:power_state']) {
            case 0:
                state = 'NOSTATE';
                break;
            case 1:
                state = 'RUNNING';
                break;
            case 3:
                state = 'PAUSED';
                break;
            case 4:
                state = 'SHUTDOWN';
                break;
            case 6:
                state = 'CRASHED';
                break;
            case 7:
                state = 'SUSPENDED';
                break;
            default:
                break;
        }
        return state;
    }

    /**
     * get Server IPs
     * @param {*} server 
     * @returns 
     */
    const hasFloatingIP = (server = {}) => { // obtain all servers registered in the server
        const fixeds = [];
        Object.keys(server.addresses).forEach(key => {
            const ips = server.addresses[key].map(address => address['OS-EXT-IPS:type']);
            fixeds.push(...ips);
        });
        return fixeds.includes('floating');
        ///console.log(allIps.join(', '));
    }

    /**
     * get Server Security Groups
     * @param {*} server 
     * @returns 
     */
    const getInstanceSecurityGroups = (security_group_names = []) => { // obtain all servers registered in the server
        const allIps = [];
        const ips = security_group_names.map(securityGroup => securityGroup.name);
        allIps.push(...ips);
        return allIps.join(', ');
    }

    /**
     * get All Images
     */
    const getImages = async () => {
        await axios.get(`${getHostNameServer()}/images`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.images.length > 0) {
                setImages(res.data.images);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the images: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * get All Flavors
     */
    const getFlavors = async () => {
        await axios.get(`${getHostNameServer()}/flavors`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.flavors.length > 0) {
                setFlavors(res.data.flavors);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the flavors: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * get Flavor
     * @param {*} flavorID
     */
    const getFlavor = async (flavorID) => {
        await axios.get(`${getHostNameServer()}/flavors/${flavorID}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.flavor) {
                setFlavor(res.data.flavor);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Flavor: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * show Flavor
     * @param {*} flavorID
     */
    const getInstanceFlavor = async (flavorID) => {
        setInstanceFlavor({});
        await axios.get(`${getHostNameServer()}/flavors/${flavorID}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.flavor) {
                setInstanceFlavor(res.data.flavor);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Flavor: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    /**
     * get All Availability Zones
     */
    const getAvailabilityZones = async () => {
        await axios.get(`${getHostNameServer()}/availability-zone`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.availabilityZoneInfo.length > 0) {
                setAvalilabilityZones(res.data.availabilityZoneInfo);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Availability Zones: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * get All Key Pairs
     */
    const getKeyPairs = async () => {
        await axios.get(`${getHostNameServer()}/keypairs`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.keypairs.length > 0) {
                setKeyPairs(res.data.keypairs);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the KeyPairs: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * get All Networks
     */
    const getNetworks = async () => { // obtain all Networks
        await axios.get(`${getHostNameServer()}/networks`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.networks.length > 0) {
                setNetworks(res.data.networks);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Networks: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * get All Ports
     */
    const getPorts = async () => { // obtain all Ports
        await axios.get(`${getHostNameServer()}/ports`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.ports.length > 0) {
                setPorts(res.data.ports);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Ports: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    /**
     * get All Security Groups
     */
    const getSecurityGroups = async () => { // obtain all Security Groups
        await axios.get(`${getHostNameServer()}/security-groups`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.security_groups.length > 0) {
                setSecurityGroups(res.data.security_groups);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Networks: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * Associate Floating IP
     * @param {*} instance_id 
     */
    const onAssociateFloatingIP = async (instance_id) => {
        const toastId = toast.loading("Associating Floating IP please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/add-floating-ip`, { floatingip: floatingIP }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Associated Floating IP...", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleCloseModalEditSecurityGroup();
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Disassociate Floating IṔ
     * @param {*} instance_id 
     * @param {*} associatedIp 
     */
    const onDisassociateFloatingIP = async (instance_id, associatedIp) => {
        const floating = [];
        Object.keys(associatedIp).forEach(key => {
            associatedIp[key].map((address) => {
                if (address['OS-EXT-IPS:type'] === 'floating') {
                    floating.push(address.addr);
                }
            });
        });
        const toastId = toast.loading("Diassociating Floating IP please wait...");
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/disassociate-floating-ip`, { address: floating[0] }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Disassociated Floating IP '" + floating[0] + "'", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            //handleCloseModal();
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Add security group to instance
     * @param {*} security_group_name 
     */
    const addSecurityGroup = async (security_group_name) => {
        const toastId = toast.loading("Adding Security Group '" + security_group_name + "' please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instanceID}/add-security-group`, { addSecurityGroup: { name: security_group_name } }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Added Security Group '" + security_group_name + "'...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            getInstance();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getSecurityGroups();
        });
    }

    /**
     * Remove security group to instance
     * @param {*} security_group_name 
     */
    const removeSecurityGroup = async (security_group_name) => {
        const toastId = toast.loading("Removing Security Group '" + security_group_name + "' please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instanceID}/remove-security-group`, { removeSecurityGroup: { name: security_group_name } }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Removed Security Group '" + security_group_name + "'...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
            getInstance();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getSecurityGroups();
        });
    }

    /**
     * Attach Volume to instance
     * @param {*} e 
     */
    const onAttachVolume = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Attaching Volume please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instanceID}/volume-attachments`, { volumeAttachment: attachVolume }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Attached Volume...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleCloseModalAttachVolume();
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getVolumes();
        });
    }

    /**
     * Detach Volume to instance
     * @param {*} e 
     */
    const onDetachVolume = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Detaching Volume please wait...", { autoClose: 5000 });
        await axios.delete(`${getHostNameServer()}/instances/${instanceID}/volume-detach/${detachVolume.volume_id}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Detached Volume...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleCloseModalDetachVolume();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
            getVolumes();
        });
    }

    /**
     * get All Volumes
     */
    const getVolumes = async () => {
        await axios.get(`${getHostNameServer()}/volumes/detail`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.volumes.length > 0) {
                setVolumes(res.data.volumes);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the KeyPairs: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    /**
     * Start instance
     * @param {*} instance_id 
     */
    const onStartInstance = async (instance_id) => {
        const toastId = toast.loading("Starting Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/start`, { "os-start": null }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Starting Instance...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Stop instance
     * @param {*} instance_id 
     */
    const onStopInstance = async (instance_id) => {
        const toastId = toast.loading("Stopping Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/stop`, { "os-stop": null }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Stopped Instance...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Resize instance
     */
    const onResizeInstance = async (e) => {
        e.preventDefault();
        setResizing(true);
        const toastId = toast.loading("Resizing Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instanceID}/resize`, { "resize": { "flavorRef": resizeInstance.flavorRef } }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Resizing Instance...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            setResizing(false);
            handleCloseModalResizeInstance();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Confirm Resize instance
     */
    const onConfirmResizeInstance = async (instance_id) => {
        const toastId = toast.loading("Resizing Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/confirm`, { "confirmResize": null }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Confirmed Resize Instance...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Revert Resize instance
     */
    const onRevertResizeInstance = async (instance_id) => {
        const toastId = toast.loading("Reverting Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/revert`, { "revertResize": null }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Reverted Resize Instance...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * 
     * @param {*} IP 
     * @returns
     */
    const validateIP = (IP) => {
        let ipv4 = /^(\d{1,3}\.){3}\d{1,3}$/;
        let ipv6 = /^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$/i;
        return ipv4.test(IP) || ipv6.test(IP);
    }

    /**
     * Attach interface
     */
    const onAttachInterface = async (e) => {
        e.preventDefault();
        setAttaching(true);
        let jsonBody = {};
        if (attachInterface.ip_address !== '' && validateIP(attachInterface.ip_address)) {
            jsonBody = {
                fixed_ips: [
                    { ip_address: attachInterface.ip_address }
                ],
                net_id: attachInterface.net_id
            }
        } else {
            jsonBody = {
                net_id: attachInterface.net_id
            }
        }
        const toastId = toast.loading("Attaching Interface please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instanceID}/attach-interface`, { interfaceAttachment: jsonBody }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Attached Interfcae...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            setAttaching(false);
            handleCloseModalAttachInterface();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Detach interface
     */
    const onDetachInterface = async (e) => {
        e.preventDefault();
        setDetaching(true);
        const toastId = toast.loading("Detaching Interface please wait...", { autoClose: 5000 });
        await axios.delete(`${getHostNameServer()}/instances/${instanceID}/detach-interface/${detachInterface.port_id}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "The interface will soon be removed", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleCloseModalDetachInterface();
            setDetaching(false);
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Reboot instance
     * @param {*} instance_id 
     */
    const onRebootInstance = async (instance_id) => {
        const toastId = toast.loading("Rebooting Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/reboot`, { reboot: { type: "SOFT" } }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Rebooting Instance...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Pause instance
     * @param {*} instance_id 
     */
    const onPauseInstance = async (instance_id) => {
        const toastId = toast.loading("Pausing Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/pause`, { pause: null }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Instance paused...", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Resume instance
     * @param {*} instance_id 
     */
    const onResumeInstance = async (instance_id) => {
        const toastId = toast.loading("Resuming Instance please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instance_id}/resume`, { unpause: null }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Instance resumed...", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**
     * Create Snapshot from instance
     * @param {*} e 
     */
    const onCreateSnapshotFromInstance = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Creating Image from Instance please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/instances/${instanceID}/create-snapshot`, { createImage: snapshot }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Image successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            console.log(res.data);
            handleCloseModalSnapshot();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getInstances();
        });
    }

    /**const columns = [
        {
            ///id: row => row.id,
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Instance ID',
            selector: row => row.id,
            sortable: true
        },
        {
            name: 'Instance Type',
            selector: row => row.flavor.id,
            sortable: true
        },
        {
            name: 'IPv4',
            selector: row => getIps(row),
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        },
        {
            name: 'Availability Zone',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Task',
            selector: row => row['OS-EXT-STS:task_state'] ? <ProgressBar animated now={100} label={row['OS-EXT-STS:task_state'].toUpperCase()} /> : 'None',
            sortable: true
        },
        {
            name: 'Power State',
            selector: row => getStates(row),
            sortable: true
        },
        {
            name: 'Security Group',
            selector: row => getInstanceSecurityGroups(row.security_groups),
            sortable: true
        },
        {
            name: 'Key Name',
            selector: row => row.key_name ? row.key_name : '-',
            sortable: true
        },
        {
            name: 'Launch Time',
            selector: row => moment(row['OS-SRV-USG:launched_at']).tz(timeZone).fromNow(),
            sortable: true
        },
        {
            name: 'Operations',
            allowOverflow: true,
            ignoreRowClick: true,
            button: true,
            cell: (row) => <Operations row={row} />,
            sortable: true
        },
    ];

    const Operations = ({ row }) => {
        return (
            <DropdownButton key={row.id} variant="outline-secondary" aria-haspopup="true" flip={true} style={{ position: 'static' }} id="actions" size="sm" title="Actions">
                {(typeof row.addresses !== 'undefined' && hasFloatingIP(row)) ?
                    <Dropdown.Item href="#" onClick={() => onDisassociateFloatingIP(row.id, row.addresses)}>Disassociate Floating IP</Dropdown.Item> :
                    <Dropdown.Item href="#" onClick={() => onAssociateFloatingIP(row.id)}>Associate Floating IP</Dropdown.Item>
                }
                <Dropdown.Item href="#" onClick={() => handleShowModalSnapshot(row.id)}>Create Snapshot</Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => handleShowModalAttachVolume(row.id)}>Attach volume</Dropdown.Item>
                {row['os-extended-volumes:volumes_attached'].length > 0 &&
                    <Dropdown.Item href="#" onClick={() => handleShowModalDetachVolume(row.id)}>Detach volume</Dropdown.Item>
                }
                <Dropdown.Item href="#" onClick={() => handleShowModalEditSecurityGroup(row.id, (typeof row.security_groups !== 'undefined') ? row.security_groups : [])}>Edit Security Group</Dropdown.Item>
                {getStates(row) === 'PAUSED' && (
                    <Dropdown.Item href="#" onClick={() => onResumeInstance(row.id)}>Resume instance</Dropdown.Item>
                )}
                {getStates(row) === 'RUNNING' && (
                    <>
                        <Dropdown.Item href="#" onClick={() => onPauseInstance(row.id)}>Pause instance</Dropdown.Item>
                    </>
                )}
                <Dropdown.Item href="#" onClick={() => onRebootInstance(row.id)}>Reboot instance</Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => onDeleteInstance(row.id)}>Delete Instance</Dropdown.Item>
            </DropdownButton>
        );
    }*/

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Instance flavor</Popover.Header>
            <Popover.Body>
                <table className="table table-bordered">
                    <tbody>
                        <>
                            <tr>
                                <th>ID: </th>
                                <td>{instanceFlavor.id}</td>
                            </tr>
                            <tr>
                                <th>Name: </th>
                                <td>{instanceFlavor.name}</td>
                            </tr>
                            <tr>
                                <th>RAM: </th>
                                <td>{instanceFlavor.ram} MiB</td>
                            </tr>
                            <tr>
                                <th>VCPUs: </th>
                                <td>{instanceFlavor.vcpus}</td>
                            </tr>
                            <tr>
                                <th>DISK: </th>
                                <td>{instanceFlavor.disk} GiB</td>
                            </tr>
                        </>
                    </tbody>
                </table>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <Row>
                <Col>
                    <h3 className="floet-start">Instances</h3>
                </Col>
                <Col>
                    <div className="float-end">
                        <Button variant="outline-dark" onClick={() => getInstances()} size="sm" className="mb-3">
                            <i className="bi bi-arrow-clockwise"></i>
                        </Button>{' '}
                        <Button variant="outline-primary" onClick={handleShow} size="sm" className="mb-3">
                            Launch instance
                        </Button>
                    </div>
                </Col>
            </Row>
            {/** <div style={{ position: 'relative' }}>
                <DataTable columns={columns} data={instances} progressPending={loadingInstances} fixedHeader responsive selectableRows pagination />
            </div> */}
            <Table striped bordered responsive hover size="sm" ref={tableRef} id="table-instances" style={{ fontSize: '14px' }}>
                <thead className="text-secondary">
                    <tr>
                        {/* <th>...</th> */}
                        <th>Name</th>
                        <th>Instance ID</th>
                        <th>Instance Type</th>
                        <th>IPv4</th>
                        <th>Status</th>
                        <th>Availability Zone</th>
                        <th>Task</th>
                        <th>Power State</th>
                        <th>Security group name</th>
                        <th>Key name</th>
                        <th>Launch time</th>
                        <th>Operations</th>
                    </tr>
                </thead>
                <tbody>
                    {instances.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={13}>
                                {loadingInstances ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any instances</p>}
                                <Button variant="outline-secondary" onClick={handleShow} size="sm" className="mb-3" disabled={loadingInstances}>
                                    Launch instance
                                </Button>
                            </td>
                        </tr>
                    )}
                    {instances.map((instance, _) => {
                        return (
                            <tr key={instance.id}>
                                {/* <td><Form.Check></Form.Check></td> */}
                                <td><Link to={`/compute/instances/${instance.id}/detail`}>{instance.name}</Link></td>
                                <td>{instance.id}</td>
                                <td>
                                    <OverlayTrigger trigger={"click"} placement="right" overlay={popover}>
                                        <Link onClick={() => getInstanceFlavor(instance.flavor.id)} variant="white" placeholder="click me" size="sm">Detail</Link>
                                    </OverlayTrigger>
                                </td>
                                <td>{getIps(instance)}</td>
                                <td>{instance.status}</td>
                                <td>{instance['OS-EXT-AZ:availability_zone']}</td>
                                <td>{instance['OS-EXT-STS:task_state'] ? <ProgressBar animated now={100} label={instance['OS-EXT-STS:task_state'].toUpperCase()} /> : 'None'}</td>
                                <td>{getStates(instance)}</td>
                                <td>{getInstanceSecurityGroups(instance.security_groups)}</td>
                                <td>{instance.key_name ? instance.key_name : '-'}</td>
                                <td>{moment(instance['OS-SRV-USG:launched_at']).tz(timeZone).fromNow()}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} style={{ position: 'static' }} id="actions" size="sm" title="Actions">
                                        {(typeof instance.addresses !== 'undefined' && hasFloatingIP(instance)) ?
                                            <Dropdown.Item href="#" onClick={() => onDisassociateFloatingIP(instance.id, instance.addresses)}>Disassociate Floating IP</Dropdown.Item> :
                                            <Dropdown.Item href="#" onClick={() => onAssociateFloatingIP(instance.id)}>Associate Floating IP</Dropdown.Item>
                                        }
                                        <Dropdown.Item href="#" onClick={() => handleShowModalSnapshot(instance.id)}>Create Snapshot</Dropdown.Item>
                                        {(getStates(instance) === 'RUNNING' && instance.status === 'ACTIVE') && (
                                            <>
                                                <Dropdown.Item href="#" onClick={() => handleShowModalAttachInterface(instance.id)}>Attach Interface</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={() => handleShowModalDetachInterface(instance.id)}>Detach Interface</Dropdown.Item>
                                            </>
                                        )}
                                        <Dropdown.Item href="#" onClick={() => handleShowModalAttachVolume(instance.id)}>Attach volume</Dropdown.Item>
                                        {instance['os-extended-volumes:volumes_attached'].length > 0 &&
                                            <Dropdown.Item href="#" onClick={() => handleShowModalDetachVolume(instance.id)}>Detach volume</Dropdown.Item>
                                        }
                                        <Dropdown.Item href="#" onClick={() => handleShowModalEditSecurityGroup(instance.id, (typeof instance.security_groups !== 'undefined') ? instance.security_groups : [])}>Edit Security Group</Dropdown.Item>
                                        {getStates(instance) === 'PAUSED' && (
                                            <Dropdown.Item href="#" onClick={() => onResumeInstance(instance.id)}>Resume instance</Dropdown.Item>
                                        )}
                                        {instance.status === 'SHUTOFF' && (
                                            <Dropdown.Item href="#" onClick={() => onStartInstance(instance.id)}>Start instance</Dropdown.Item>
                                        )}
                                        {(instance.status === 'ACTIVE' || instance.status === 'ERROR') && (
                                            <Dropdown.Item href="#" onClick={() => onStopInstance(instance.id)} className="text-danger">Shut Off instance</Dropdown.Item>
                                        )}
                                        {(instance.status === 'ACTIVE' || instance.status === 'SHUTOFF') && (
                                            <Dropdown.Item href="#" onClick={() => handleShowModalResizeInstance(instance.id, instance.flavor.id)} className="text-danger">Resize instance</Dropdown.Item>
                                        )}
                                        {(instance.status === 'VERIFY_RESIZE') && (
                                            <Dropdown.Item href="#" onClick={() => onConfirmResizeInstance(instance.id)}> <Badge bg="success">Confirm</Badge> Resize instance</Dropdown.Item>
                                        )}
                                        {(instance.status === 'VERIFY_RESIZE' && instance['OS-EXT-STS:vm_state'] === 'resized') && (
                                            <Dropdown.Item href="#" onClick={() => onRevertResizeInstance(instance.id)}> <Badge bg="primary">Revert</Badge> Resize instance</Dropdown.Item>
                                        )}
                                        {getStates(instance) === 'RUNNING' && (
                                            <>
                                                <Dropdown.Item href="#" onClick={() => onPauseInstance(instance.id)}>Pause instance</Dropdown.Item>
                                            </>
                                        )}
                                        <Dropdown.Item href="#" onClick={() => onRebootInstance(instance.id)} className="text-danger">Reboot instance</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => onDeleteInstance(instance.id)} className="text-danger">Delete Instance</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} backdrop="static" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Launch Instance</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onSubmitInstance}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" name="name" size="sm" placeholder="Enter name" onChange={handleLaunchInstance} pattern="[a-zA-Z0-9_]+" maxLength={20} disabled={launchingInstance} required />
                            <Form.Text className="text-muted">
                                Do not use special characters, only underscores, numbers and letters without spaces.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" name="description" value={instance.description} size="sm" rows={1} placeholder="Description" onChange={handleLaunchInstance} disabled={launchingInstance} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="availability_zone">
                            <Form.Label>Availability Zone <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="availability_zone" onChange={handleLaunchInstance} size="sm" disabled={!!((availabilityZones.length === 0 || launchingInstance))} required >
                                <option value="">Select zone</option>
                                {availabilityZones.map((availabilityZone, _i, _array) => {
                                    return (<option value={availabilityZone.zoneName} key={availabilityZone.zoneName}>{availabilityZone.zoneName}</option>)
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="imageRef">
                            <Form.Label>Image S.0 <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="imageRef" onChange={handleLaunchInstance} size="sm" disabled={!!((images.length === 0 || launchingInstance))} required >
                                {images.length === 0 && (
                                    <option value="">Loading...</option>
                                )}
                                {images.length > 0 && (
                                    <option value="">Select image</option>
                                )}
                                {images.map((image, i, array) => {
                                    return (<option value={image.id} key={image.id}>{image.name}</option>)
                                })}
                            </Form.Select>
                            {/* <Select isSearchable={true} options={columns} name="imageRef" value={columns[0]} onChange={onImages} /> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="flavorRef">
                            <Form.Label>Flavor <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="flavorRef" onChange={handleLaunchInstance} size="sm" disabled={!!((flavors.length === 0 || launchingInstance))} required >
                                {flavors.length === 0 && (
                                    <option value="">Loading...</option>
                                )}
                                {flavors.length > 0 && (
                                    <option value="">Select flavor</option>
                                )}
                                {flavors.map((flavor, i, array) => {
                                    return (<option value={flavor.id} key={flavor.id}>{flavor.name}: {flavor.vcpus} Cores, {flavor.ram / 1024} GB RAM, {flavor.disk} Temporary storage</option>)
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="networks">
                            <Form.Label>Networks <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="networks" onChange={handleLaunchInstance} size="sm" disabled={!!((networks.length === 0 || launchingInstance))} required >
                                {networks.length === 0 && (
                                    <option value="">Loading...</option>
                                )}
                                {networks.length > 0 && (
                                    <option value="">Select network</option>
                                )}
                                {networks.map((network, i, array) => {
                                    return (<option value={network.id} key={network.id}>{network.name}</option>)
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="security_groups">
                            <Form.Label>Security Group <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="security_groups" onChange={handleLaunchInstance} size="sm" disabled={!!((securityGroups.length === 0 || launchingInstance))} required >
                                {securityGroups.length === 0 && (
                                    <option value="">Loading...</option>
                                )}
                                {securityGroups.length > 0 && (
                                    <option value="">Select Security Group</option>
                                )}
                                {securityGroups.map((securityGroup, i, array) => {
                                    return (<option value={securityGroup.name} key={securityGroup.id}>{securityGroup.name}</option>)
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="key_name">
                            <Form.Label>Key Pair <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="key_name" onChange={handleLaunchInstance} size="sm" disabled={!!((keyPairs.length === 0 || launchingInstance))} required >
                                {keyPairs.length === 0 && (
                                    <option value="">Loading...</option>
                                )}
                                {keyPairs.length > 0 && (
                                    <option value="">Select Key Pair</option>
                                )}
                                {keyPairs.map((key, _i, _array) => {
                                    return (<option value={key.keypair.name} key={key.keypair.name}>{key.keypair.name}</option>)
                                })}
                            </Form.Select>
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="public_key">
                            <Form.Label>SSH Key <span className="text-danger">*</span></Form.Label>
                            <Form.Control as="textarea" name="public_key" style={{ fontSize: '12px' }} rows={6} placeholder="Paste Public sshKey here" onChange={handleLaunchInstance} disabled={launchingInstance} required />
                            <Form.Text className="text-muted">
                                We'll never share your key with anyone else.
                            </Form.Text>
                        </Form.Group> */}
                        <Form.Group className="mb-3" controlId="enablePassword">
                            <Form.Check type="checkbox" name="enablePassword" onChange={handleEnablePassword} defaultChecked={enablePassword} label="With Password" disabled={launchingInstance} />
                        </Form.Group>
                        {enablePassword && (
                            <>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="password" minLength={8} value={instance.password} onChange={handleLaunchInstance} name="password" size="sm" placeholder="Type your Password" disabled={launchingInstance} required />
                                </Form.Group>
                            </>
                        )}
                        <Button variant="primary" size="sm" type="submit" disabled={launchingInstance}>
                            Launch
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <Modal size="lg" centered show={showModalEditSecurityGroup} onHide={handleCloseModalEditSecurityGroup} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Security Groups</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Add and remove security groups to this instance from the list of available security groups.
                    </p>
                    <p>
                        <strong>Warning:</strong> If you change security groups here, the change will be applied to all interfaces of the instance. If you have multiple interfaces on this instance and apply different security groups per port, use "Edit Port Security Groups" action instead.
                    </p>
                    <Row>
                        <Col>
                            <strong>All Security Groups</strong>
                            <ListGroup as="ol">
                                {unAssigned.map((name) => {
                                    return (
                                        <ListGroup.Item
                                            key={name}
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{name}</div>
                                                Active
                                            </div>
                                            <Button size="sm" onClick={() => addSecurityGroup(name)}><i className="bi bi-plus-lg"></i></Button>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Col>
                        <Col>
                            <strong>Instance Security Groups</strong>
                            <ListGroup as="ol">
                                {assigned.map((name) => {
                                    return (
                                        <ListGroup.Item
                                            key={name}
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{name}</div>
                                                Active
                                            </div>
                                            <Button size="sm" onClick={() => removeSecurityGroup(name)}><i className="bi bi-dash-lg"></i></Button>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={handleCloseModalEditSecurityGroup}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="sm" centered show={showModalAttachVolume} onHide={handleCloseModalAttachVolume} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Volumes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Warning:</strong> Attach Volume to Running Instance.
                    </p>
                    <Row>
                        <Col>
                            <Form onSubmit={onAttachVolume}>
                                <Form.Group className="mb-3" controlId="volumeId">
                                    <Form.Label>Security Group <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="volumeId" onChange={handleAttachVolume} size="sm" required >
                                        <option value="">Select volume</option>
                                        {volumes.map((volume, _) => {
                                            return (
                                                <option key={volume.id} value={volume.id}>{volume.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Button variant="secondary" size="sm" onClick={handleCloseModalAttachVolume}>
                                    Cancel
                                </Button>{' '}
                                <Button type="submit" variant="primary" size="sm">
                                    Attach Volume
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={showModalDetachVolume} onHide={handleCloseModalDetachVolume} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Volumes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Warning:</strong> Detach Volume to Running Instance.
                    </p>
                    <Row>
                        <Col>
                            <Form onSubmit={onDetachVolume}>
                                <Form.Group className="mb-3" controlId="volume_id">
                                    <Form.Label>Security Group <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="volume_id" onChange={handleDetachVolume} size="sm" required >
                                        <option value="">Select volume</option>
                                        {volumes.map((volume, _) => {
                                            return (
                                                <option key={volume.id} value={volume.id}>{volume.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Button variant="secondary" size="sm" onClick={handleCloseModalDetachVolume}>
                                    Cancel
                                </Button>{' '}
                                <Button type="submit" variant="primary" size="sm">
                                    Detach Volume
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={showModalSnapshot} onHide={handleCloseModalSnapshot} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Snapshot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Description:</strong> A snapshot is an image which preserves the disk state of a running instance.
                    </p>
                    <Row>
                        <Col>
                            <Form onSubmit={onCreateSnapshotFromInstance}>
                                <Form.Group className="mb-3" controlId="volumeId">
                                    <Form.Label>Snapshot Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" name="name" value={snapshot.name} onChange={handleSnapshot} placeholder="" pattern="/^[a-zA-Z0-9_-]*$/" required />
                                </Form.Group>
                                <Button variant="secondary" size="sm" onClick={handleCloseModalSnapshot}>
                                    Cancel
                                </Button>{' '}
                                <Button type="submit" variant="primary" size="sm">
                                    Create Snapshot
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={showModalResizeInstance} onHide={handleCloseModalResizeInstance} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Resize Instance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form onSubmit={onResizeInstance}>
                                <Form.Group className="mb-3" controlId="volumeId">
                                    <Form.Label>Old Flavor <span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={flavor.name ? flavor.name : ''} size="sm" disabled={true} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="flavorRef">
                                    <Form.Label>New Flavor <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="flavorRef" value={resizeInstance.flavorRef} onChange={handleResizeIntance} size="sm" disabled={(flavors.length === 0 || resizing === true) ? true : false} required >
                                        {flavors.length === 0 && (
                                            <option value="">Loading...</option>
                                        )}
                                        {flavors.length > 0 && (
                                            <option value="">Select flavor</option>
                                        )}
                                        {flavors.map((flavor, i, array) => {
                                            return (<option value={flavor.id} key={flavor.id}>{flavor.name}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Button variant="secondary" size="sm" onClick={handleCloseModalResizeInstance} disabled={resizing}>
                                    Cancel
                                </Button>{' '}
                                <Button type="submit" variant="primary" size="sm" disabled={resizing}>
                                    Resize
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={showModalAttachInterface} onHide={handleCloseModalAttachInterface} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Attach Interface</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5><strong>Description:</strong></h5>
                    <span>Select the network for interface attaching.</span>

                    <Row>
                        <Col>
                            <Form onSubmit={onAttachInterface}>
                                {/* <Form.Group className="mb-3" controlId="volumeId">
                                    <Form.Label>The way to specify an interface <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="port_id" value={attachInterface.net_id} onChange={handleAttachInterface} size="sm" disabled={(networks.length === 0 || attaching === true) ? true : false} required >
                                        {networks.length === 0 && (
                                            <option value="">Loading...</option>
                                        )}
                                        {networks.length > 0 && (
                                            <option value="">Select network</option>
                                        )}
                                        {networks.map((network, i, array) => {
                                            return (<option value={network.id} key={i}>{network.name}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="interfaces">
                                    <Form.Label>Network <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="net_id" value={attachInterface.net_id} onChange={handleAttachInterface} size="sm" disabled={(networks.length === 0 || attaching === true) ? true : false} required >
                                        {networks.length === 0 && (
                                            <option value="">Loading...</option>
                                        )}
                                        {networks.length > 0 && (
                                            <option value="">Select network</option>
                                        )}
                                        {networks.map((network, i, array) => {
                                            return (<option value={network.id} key={network.id}>{network.name}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="volumeId">
                                    <Form.Label>Fixed IP Address</Form.Label>
                                    <Form.Control name="ip_address" value={attachInterface.ip_address} onChange={handleAttachInterface} size="sm" pattern="^(\d{1,3}\.){3}\d{1,3}$|^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$" disabled={attaching} />
                                    <Form.Text className="text-muted">
                                        Example IPv4: 192.168.0.1,<br />IPv6: 2001:db8:0:0:0:ff00:42:8329
                                    </Form.Text>
                                </Form.Group>
                                <Button variant="secondary" size="sm" onClick={handleCloseModalAttachInterface} disabled={attaching}>
                                    Cancel
                                </Button>{' '}
                                <Button type="submit" variant="primary" size="sm" disabled={attaching}>
                                    Attach interface
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal size="sm" centered show={showModalDetachInterface} onHide={handleCloseModalDetachInterface} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Detach Interface</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5><strong>Description:</strong></h5>
                    <span>Select the port to detach.</span>
                    <Row>
                        <Col>
                            <Form onSubmit={onDetachInterface}>
                                <Form.Group className="mb-3" controlId="detachInterface">
                                    <Form.Label>Port <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="port_id" value={detachInterface.port_id} onChange={handleDetachInterface} size="sm" disabled={(ports.length === 0 || detaching === true) ? true : false} required >
                                        {ports.length === 0 && (
                                            <option value="">Loading...</option>
                                        )}
                                        {ports.length > 0 && (
                                            <option value="">Select Port</option>
                                        )}
                                        {ports.map((port, i, array) => {
                                            return (<option value={port.id} key={port.id}>{getIpPort(port)}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Button variant="secondary" size="sm" onClick={handleCloseModalDetachInterface} disabled={detaching}>
                                    Cancel
                                </Button>{' '}
                                <Button type="submit" variant="primary" size="sm" disabled={detaching}>
                                    Detach interface
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </>
    );

}
export default Instances;