import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Offcanvas, Overlay, Popover, Row, Spinner, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../../services/helpers/config";
import { Link } from "react-router-dom";

const Routers = () => {

    const { keycloak, initialized } = useKeycloak();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [enableAdminStateUp, setEnableAdminStateUp] = useState(true);

    const [routers, setRouters] = useState([]);
    const [router, setRouter] = useState({ name: '', floating_network_id: '', admin_state_up: enableAdminStateUp, availability_zone_hints: [] });
    const [loadingRouters, setLoadingRouters] = useState(false);
    const [creatingRouter, setCreatingRouter] = useState(false);

    const [showInfo, setShowInfo] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [showContent, setShowContent] = useState({});

    useEffect(() => {
        console.log(initialized);
        getRouters();
    }, []);

    const handleChangeEnableAdminStateUp = (event) => {
        if (event.target.name === 'admin_state_up') {
            setEnableAdminStateUp(event.target.checked);
        }
    }

    const handleChangeRouter = (event) => {
        const fields = { name: router.name, admin_state_up: enableAdminStateUp, availability_zone_hints: router.availability_zone_hints };
        fields[event.target.name] = event.target.value;
        setRouter(fields);
    }

    const onSubmitRouter = async (e) => {
        e.preventDefault();
        setCreatingRouter(true);
        router.admin_state_up = enableAdminStateUp;
        router.availability_zone_hints = router.availability_zone_hints.length > 0 ? [router.availability_zone_hints.join(', ')] : [];
        const toastId = toast.loading("Creating Router please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/routers`, { router: router }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            setCreatingRouter(false);
            handleClose();
            toast.update(toastId, { render: "Router successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getRouters();
        });
    }

    const onDeleteRouter = async (router_id) => {
        if (window.confirm('This Router will be permanently deleted')) {
            const toastId = toast.loading("Deleting Router please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/routers/${router_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Router successfully deleted", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getRouters();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    /**const getExternalNetwork = async (network_id) => {
        await axios.get(`${getHostNameServer()}/networks/${network_id}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                return res.data.network.name;
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }*/

    const handleClick = async (event, network_id) => {
        await axios.get(`${getHostNameServer()}/networks/${network_id}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                setShowContent(res.data.network);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
        setShowInfo(!showInfo);
        setTarget(event.target);

    };

    const getRouters = () => {
        setLoadingRouters(true);
        axios.get(`${getHostNameServer()}/routers`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                ///console.log(res.data.routers);
                setRouters(res.data.routers);
            }
            setLoadingRouters(false);
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <h3 className="floet-start">Routers</h3>
                </Col>
                <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3">
                        Create Router
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Network Name</th>
                        <th>Status</th>
                        <th>External Network</th>
                        <th>Admin State</th>
                        <th>Availability Zone</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {routers.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={7}>
                                {loadingRouters ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any routers</p>}
                                <Button variant="outline-secondary" onClick={handleShow} size="sm" className="mb-3" disabled={loadingRouters}>
                                    Create router
                                </Button>
                            </td>
                        </tr>
                    )}
                    {routers.map((router, _) => {
                        return (
                            <tr key={router.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{router.name}</td>
                                <td>{router.status}</td>
                                <td>{/*router.external_gateway_info.network_id*/showContent ? showContent.name : 'Loading...'}
                                    <div ref={ref} className="float-end">
                                        <i className="bi bi-eye" onClick={(e) => handleClick(e, router.external_gateway_info.network_id)}></i>
                                        <Overlay
                                            show={showInfo}
                                            target={target}
                                            placement="bottom"
                                            container={ref}
                                            containerPadding={20}
                                        >
                                            <Popover id="popover-contained">
                                                <Popover.Header as="h3">Details</Popover.Header>
                                                <Popover.Body>
                                                    <strong>ID: </strong>{showContent ? showContent.id : 'Loading...'}<br />
                                                    <strong>Name: </strong>{showContent ? showContent.name : 'Loading...'}<br />
                                                    <strong>Status: </strong>{showContent ? showContent.status : 'Loading...'}<br />
                                                </Popover.Body>
                                            </Popover>
                                        </Overlay>
                                    </div>
                                </td>
                                <td>{router.admin_state_up ? 'UP' : 'DOWN'}</td>
                                <td>{router.availability_zones.length > 0 ? router.availability_zones.join(', ') : '-'}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Link className="dropdown-item" to={`/network/routers/${router.id}`}>Manage Router</Link>
                                        <Dropdown.Item href="#" onClick={() => onDeleteRouter(router.id)}>Delete Router</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><strong>Create Router</strong></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onSubmitRouter}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" name="name" size="sm" placeholder="Enter name" value={router.name} onChange={handleChangeRouter} pattern="[a-zA-Z_]*" maxLength={20} disabled={creatingRouter} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="admin_state_up">
                            <Form.Check type="checkbox" name="admin_state_up" defaultValue={enableAdminStateUp} onChange={handleChangeEnableAdminStateUp} defaultChecked={enableAdminStateUp} label="Enable Admin State"  disabled={creatingRouter} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="networks">
                            <Form.Label>External Network <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="networks" onChange={handleChangeRouter} size="sm"  disabled={creatingRouter}>
                                <option value="">external_network</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Label>availability Zone Hints</Form.Label>
                            <Form.Control as="textarea" name="availability_zone_hints" onChange={handleChangeRouter} rows={4} placeholder="" value={router.availability_zone_hints}  disabled={creatingRouter} />
                        </Form.Group>

                        <Button variant="primary" size="sm" type="submit" disabled={creatingRouter}>
                            Create Router
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button"  disabled={creatingRouter}>
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );

}
export default Routers;