import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Interfaces from "./interfaces";
import { Tab, Tabs } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";

const Manage = () => {

    const { keycloak, initialized } = useKeycloak();
    let { id } = useParams();

    useEffect(() => {
        console.log(initialized, keycloak.authenticated);
    }, []);

    return (
        <>
            <Tabs
                defaultActiveKey="interfaces"
                id="uncontrolled-tab-manages"
                className="mb-3"
            >
                {/* <Tab eventKey="overview" title="Overview">
                    <h3>Overview</h3>
                </Tab> */}
                <Tab eventKey="interfaces" title="Interfaces">
                    <Interfaces id={id} />
                </Tab>
                {/* <Tab eventKey="contact" title="Others">
                <h1>Others</h1>
                </Tab> */}
            </Tabs>
            <ToastContainer />
        </>
    );

}
export default Manage;