import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Footer from "./footer";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { getHostNameServer } from "../../services/helpers/config";
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { StatusContext } from "../../contexts/status-context";
import { ToastContainer, toast } from "react-toastify";

const Layout = () => {

    const [status, setStatus] = useContext(StatusContext);

    const { keycloak, initialized } = useKeycloak();
    const sidebar_toggle = useRef();
    const [verified, setVerified] = useState(false);
    const [verifying, setVerifying] = useState(false);

    const isPassed = useMemo(() => {
        return status;
    }, [status]);

    const [enableChangePassword, setEnableChangePassword] = useState(false);
    const [changingPassword, setChangingPassword] = useState(false);
    const [user, setUser] = useState({ password: '' });
    const [credentials, setCredentials] = useState({});
    const [showModalCredentials, setShowModalCredentials] = useState(false);
    const handleCloseModalCredentials = () => {
        setShowModalCredentials(false);
    }
    const handleShowModalCredentials = () => {
        getCredentials();
        setShowModalCredentials(true);
    }

    useEffect(() => {
        console.log(initialized);
        verify();
        /** Navbar links active state on scroll */
        let navbarlinks = select('#navbar .scrollto', true);
        const navbarlinksActive = () => {
            let position = window.scrollY + 200
            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) return
                let section = select(navbarlink.hash)
                if (!section) return
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    navbarlink.classList.add('active');
                } else {
                    navbarlink.classList.remove('active');
                }
            });
        }
        window.addEventListener('load', navbarlinksActive);
        onscroll(document, navbarlinksActive);
        toggleHeaderScrolled();
        backTopButton();
    }, []);

    /** 
     * Toggle .header-scrolled class to #header when page is scrolled 
    */
    const toggleHeaderScrolled = () => {
        let selectHeader = select('#header');
        if (selectHeader) {
            const headerScrolled = () => {
                if (window.scrollY > 100) {
                    selectHeader.classList.add('header-scrolled')
                } else {
                    selectHeader.classList.remove('header-scrolled')
                }
            }
            window.addEventListener('load', headerScrolled)
            onscroll(document, headerScrolled)
        }
    }

    /** 
     * Back to top button 
     */
    const backTopButton = () => {
        let backtotop = select('.back-to-top');
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add('active')
                } else {
                    backtotop.classList.remove('active')
                }
            }
            window.addEventListener('load', toggleBacktotop)
            onscroll(document, toggleBacktotop)
        }
    }

    /** Easy selector helper function */
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * Easy event listener function
     */
    /**const on = (type, el, listener, all = false) => {
      if (all) {
        select(el, all).forEach(e => e.addEventListener(type, listener))
      } else {
        select(el, all).addEventListener(type, listener)
      }
    }*/

    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    const onToggleSidebarBtn = () => {
        document.querySelector('#body').classList.toggle('toggle-sidebar');
    }

    const verify = () => {
        setVerifying(true);
        /** load data bookings from sever */
        axios.post(`${getHostNameServer()}/identity/verify`, {}, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200 && res.data.ready === true) {
                setStatus({ verified: true, passed: true });
                setVerified(true);
                setVerifying(false);
            } else {
                setVerified(false);
                setVerifying(false);
                console.log('Not Ready');
            }
        }).catch((err) => {
            setStatus({ verified: false, passed: false });
        }).finally(() => {
            setVerifying(false);
        });
    }

    const handleEnableChangePassword = () => {
        setEnableChangePassword(!enableChangePassword);
    }

    const handleChangePassword = (event) => {
        const fields = { password: user.password };
        fields[event.target.name] = event.target.value;
        setUser(fields);
    }

    /**
     * Change password
     * @param {*} e 
     */
    const onSubmitChangePassword = async (e) => {
        e.preventDefault();
        setChangingPassword(true);
        const toastId = toast.loading("Changing password, please wait...", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/account/password`, { user: user }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Successful password change!", type: "info", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            setChangingPassword(false);
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    const getCredentials = async () => {
        const toastId = toast.loading("Requesting Credentials please wait...", { autoClose: 5000 });
        await axios.get(`${getHostNameServer()}/account/credentials`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            ///console.log(res.data);
            setCredentials(res.data);
            toast.update(toastId, { render: "Credentials loaded...", type: "info", hideProgressBar: true, isLoading: false, autoClose: 2000, closeOnClick: true });
            ///handleCloseModalCredentials();
        }).catch((err) => {
            console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    return (
        <>
            {/* <!-- ======= Header ======= --> */}
            <header id="header" className="header fixed-top d-flex align-items-center">
                {verifying && (
                    <>
                        <Spinner animation="grow" variant="info" />
                    </>
                )}
                {(isPassed.passed && isPassed.verified) && (
                    <div className="d-flex align-items-center justify-content-between">
                        <a href="/" className="logo d-flex align-items-center">
                            <img src="/icons/header_logo.png" alt="" width={100} />
                            {/* <span className="d-none d-lg-block">IDTOLU</span> */}
                        </a>
                        <i className="bi bi-list toggle-sidebar-btn" onClick={onToggleSidebarBtn} ref={sidebar_toggle}></i>
                    </div>
                )}
                {/** <div className="search-bar">
                <form className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="Search" title="Enter search keyword (soon)" />
                    <button type="button" title="Search"><i className="bi bi-search"></i></button>
                </form>
                </div> */}

                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " href="#">
                                <i className="bi bi-search"></i>
                            </a>
                        </li>

                        {/* <li className="nav-item dropdown">

                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-bell"></i>
                                <span className="badge bg-primary badge-number">4</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                <li className="dropdown-header">
                                    You have 4 new notifications
                                    <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-exclamation-circle text-warning"></i>
                                    <div>
                                        <h4>Lorem Ipsum</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>30 min. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-x-circle text-danger"></i>
                                    <div>
                                        <h4>Atque rerum nesciunt</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>1 hr. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-check-circle text-success"></i>
                                    <div>
                                        <h4>Sit rerum fuga</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>2 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-info-circle text-primary"></i>
                                    <div>
                                        <h4>Dicta reprehenderit</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>4 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="dropdown-footer">
                                    <a href="#">Show all notifications</a>
                                </li>

                            </ul>

                        </li> */}

                        {/*<li className="nav-item dropdown">

                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-chat-left-text"></i>
                                <span className="badge bg-success badge-number">3</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                                <li className="dropdown-header">
                                    You have 3 new messages
                                    <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="message-item">
                                    <a href="#">
                                        <img src="assets/img/messages-1.jpg" alt="" className="rounded-circle" />
                                        <div>
                                            <h4>Maria Hudson</h4>
                                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                            <p>4 hrs. ago</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="message-item">
                                    <a href="#">
                                        <img src="assets/img/messages-2.jpg" alt="" className="rounded-circle" />
                                        <div>
                                            <h4>Anna Nelson</h4>
                                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                            <p>6 hrs. ago</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="message-item">
                                    <a href="#">
                                        <img src="assets/img/messages-3.jpg" alt="" className="rounded-circle" />
                                        <div>
                                            <h4>David Muldon</h4>
                                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                            <p>8 hrs. ago</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="dropdown-footer">
                                    <a href="#">Show all messages</a>
                                </li>

                            </ul>

                        </li> */}

                        <li className="nav-item dropdown pe-3">

                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {/* <img src="/assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                                <span className="d-none d-md-block dropdown-toggle ps-2">{keycloak.idTokenParsed.preferred_username}</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{keycloak.idTokenParsed.name}</h6>
                                    <span>Advanced</span>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" target="_blank" href={`${keycloak.createAccountUrl()}`}>
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </a>
                                </li>
                                {/** <li>
                                    <hr className="dropdown-divider" />
                                </li>*/}

                                {(isPassed.passed && isPassed.verified && verified) && (
                                    <li>
                                        <a className="dropdown-item d-flex align-items-center" onClick={handleShowModalCredentials} href="#">
                                            <i className="bi bi-gear"></i>
                                            <span>Account Settings</span>
                                        </a>
                                    </li>
                                )}

                                {/**<li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                        <i className="bi bi-question-circle"></i>
                                        <span>Need Help?</span>
                                    </a>
                                </li> */}
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <button className="dropdown-item d-flex align-items-center" onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}` })}>
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </button>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header>

            <Modal size="lg" centered show={showModalCredentials} onHide={handleCloseModalCredentials} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Account credentials</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/** <p>
                        <strong>Warning:</strong> Detach Volume to Running Instance.
                    </p> */}
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group className="mb-3" controlId="username">
                                    <Form.Label>Username <span className="text-danger">*</span></Form.Label>
                                    <Form.Control name="name" size="sm" defaultValue={credentials.user ? credentials.user.name : ''} disabled={true} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="domain_name">
                                    <Form.Label>Domain Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control name="name" size="sm" defaultValue={credentials.user ? credentials.user.domain.name : ''} disabled={true} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="domain_id">
                                    <Form.Label>Domain ID <span className="text-danger">*</span></Form.Label>
                                    <Form.Control name="name" size="sm" defaultValue={credentials.user ? credentials.user.domain.id : ''} disabled={true} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="project_name">
                                    <Form.Label>Project Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control name="name" size="sm" defaultValue={credentials.project ? credentials.project.name : ''} disabled={true} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="project_id">
                                    <Form.Label>Project ID <span className="text-danger">*</span></Form.Label>
                                    <Form.Control name="name" size="sm" defaultValue={credentials.project ? credentials.project.id : ''} disabled={true} required />
                                </Form.Group>
                                
                                {(typeof credentials.password === 'undefined') && (
                                    <Form.Group className="mb-3" controlId="password">
                                    <Form.Text className="text-muted">
                                        <a href="#" onClick={handleEnableChangePassword}> {enableChangePassword ? 'Cancel change password now' : 'Change password now'}</a>
                                    </Form.Text>
                                </Form.Group>
                                )}
                                {(typeof credentials.password !== 'undefined') && (
                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                        <Form.Control name="name" size="sm" defaultValue={credentials.password ? credentials.password : ''} disabled={true} required />
                                        <Form.Text className="text-muted">
                                            This password is temporary. It is recommended to change it if it is the first time.
                                            <a href="#" onClick={handleEnableChangePassword}> {enableChangePassword ? 'Cancel change password now' : 'Change password now'}</a>
                                        </Form.Text>
                                    </Form.Group>
                                )}
                            </Form>
                            {enableChangePassword && (
                                <Form onSubmit={onSubmitChangePassword}>
                                    <InputGroup className="mb-3" size="sm">
                                        <Form.Control name="password"
                                            value={user.password} onChange={handleChangePassword}
                                            placeholder="Type new password"
                                            aria-label="Type new password"
                                            aria-describedby="basic-addon2"
                                            required
                                        />
                                        <Button type="submit" variant="outline-success" id="btn-change-password" disabled={changingPassword}>
                                            {changingPassword ?
                                                <>
                                                    Changing password
                                                    <Spinner animation="border" size="sm" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </>
                                                :
                                                <>
                                                    Save changes
                                                </>
                                            }
                                        </Button>
                                    </InputGroup>
                                </Form>
                            )}
                            <Button variant="secondary" size="sm" onClick={handleCloseModalCredentials}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <ToastContainer />

            {/* <!-- ======= Sidebar ======= --> */}
            {(isPassed.passed && isPassed.verified) && (
                <aside id="sidebar" className="sidebar">
                    <Sidebar />
                </aside>
            )}

            <main id="main" className="main img-body">
                <Outlet />
            </main>
            <Footer />
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </>
    );

}
export default Layout;