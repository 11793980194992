import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";
import { useKeycloak } from "@react-keycloak/web";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import bytes from "bytes";

const Instancetype = () => {

    const tableRef = useRef();
    const { keycloak, initialized } = useKeycloak();
    const [instanceTypes, setInstanceTypes] = useState([]);
    const [loadingInstanceTypes, setLoadingInstanceTypes] = useState(false);

    useEffect(() => {
        getInstanceTypes();
        console.log(initialized);
    }, []);

    const getInstanceTypes = async () => {
        setInstanceTypes([]);
        setLoadingInstanceTypes(true);
        await axios.get(`${getHostNameServer()}/flavors`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.flavors.length > 0) {
                console.log(res.data.flavors);
                setInstanceTypes(res.data.flavors);
                setLoadingInstanceTypes(false);
            }
        }).catch(err => {
            toast.error(`We are having problems loading the Instance types: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        });
    }

    const fromMb = (mb = 0) => {
        return bytes(mb * 1024 * 1024);
    }

    const fromGb = (diskGb = 0) => {
        return bytes(diskGb * 1024 * 1024 * 1024);
    }

    return (
        <>
            <Row>
                <Col>
                    <h3 className="floet-start">Instance Types</h3>
                </Col>
                <Col>
                    <div className="float-end">
                        <Button variant="outline-dark" onClick={() => getInstanceTypes()} size="sm" className="mb-3">
                            <i className="bi bi-arrow-clockwise"></i>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Table striped bordered responsive hover size="sm" ref={tableRef} id="table-instances" style={{ fontSize: '14px' }}>
                <thead className="text-secondary">
                    <tr>
                        {/* <th>...</th> */}
                        <th>Instance type</th>
                        <th>vCPUs</th>
                        <th>Architecture</th>
                        <th>Memory</th>
                        <th>Storage</th>
                        {/** <th>Storage type</th>
                        <th>Network performance</th>
                        <th>Windows pricing</th>
                        <th>Linux pricing</th> */}
                    </tr>
                </thead>
                <tbody>
                    {instanceTypes.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={13}>
                                {loadingInstanceTypes ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any instances</p>}
                            </td>
                        </tr>
                    )}
                    {instanceTypes.map((instanceType, _) => {
                        return (
                            <tr key={instanceType.id}>
                                {/* <td><Form.Check></Form.Check></td> */}
                                <td>{instanceType.name}</td>
                                <td>{instanceType.vcpus}</td>
                                <td>{'-'}</td>
                                <td>{fromMb(instanceType.ram)}</td>
                                <td>{fromGb(instanceType.disk)}</td>
                                {/** <td>{''}</td>
                                <td>{''}</td>
                                <td>{''}</td>
                                <td>{''}</td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );

}
export default Instancetype;