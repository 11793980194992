export function getIdToluIDP() {
    return 'https://account.idtolu.net/';
};

export function getIdToluRealm() {
    return 'IDT-IDT-DTC-RID-001';
};

export function getIdToluClient() {
    return 'IDT-IDT-DTC-CLD-011';
};

export function getHostNameServer() {
    return "https://backend.idtolu.net/api/v1";
};

export function getHostNameWss() {
    return "wss://backend.idtolu.net";
};

export function getHostNameEvents() {
    return 'https://events.idtolu.net';
};