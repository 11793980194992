import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Offcanvas, Row, Spinner, Table } from "react-bootstrap";
import { getHostNameServer } from "../../../services/helpers/config";
import { ToastContainer, toast } from "react-toastify";
import { saveAs } from "file-saver";

const Keypairs = () => {

    const { keycloak, initialized } = useKeycloak();

    const [showCreateKeyPair, setShowCreateKeyPair] = useState(false);
    const handleCloseCreateKeyPair = () => setShowCreateKeyPair(false);
    const handleShowCreateKeyPair = () => setShowCreateKeyPair(true);
    const [createkeyPair, setCreateKeyPair] = useState({ name: '' });
    const [creatingKeyPair, setCreatingKeyPair] = useState(false);

    const [showImportKeyPair, setShowImportKeyPair] = useState(false);
    const handleCloseImportKeyPair = () => setShowImportKeyPair(false);
    const handleShowImportKeyPair = () => setShowImportKeyPair(true);
    const [importkeyPair, setImportKeyPair] = useState({ name: '', public_key: '' });
    const [importingKeyPair, setImportingKeyPair] = useState(false);

    const [keyPairs, setKeyPairs] = useState([]);
    const [loadingKeyPairs, setLoadingKeyPairs] = useState(false);

    useEffect(() => {
        console.log(initialized);
        getKeyPairs();
    }, []);

    const handleCreateKeyPairs = (event) => {
        const fields = { name: createkeyPair.name };
        fields[event.target.name] = event.target.value;
        setCreateKeyPair(fields);
    }

    const handleImportKeyPairs = (event) => {
        const fields = { name: importkeyPair.name, public_key: importkeyPair.public_key };
        fields[event.target.name] = event.target.value;
        setImportKeyPair(fields);
    }

    const onCreateKeyPair = async (event) => {
        event.preventDefault();
        setCreatingKeyPair(true);
        const toastId = toast.loading("Creating Key Pair please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/keypairs`, { keypair: createkeyPair }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Keypair successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            if (res.status === 200) {
                setCreatingKeyPair(false);
                handleCloseCreateKeyPair();
                const { name, private_key } = res.data.keypair;
                const blob = new Blob([private_key], { type: 'text/plain;charset=utf-8' });
                saveAs(blob, `${name}.pem`);
            }
        }).catch((err) => {
            setCreatingKeyPair(false);
            console.error(err.response.status);
        }).finally(() => {
            getKeyPairs();
        });
    }

    const onImportKeyPair = async (event) => {
        event.preventDefault();
        setImportingKeyPair(true);
        const toastId = toast.loading("Importing Key Pair please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/keypairs`, { keypair: importkeyPair }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Keypair successfully inmported", type: "success", hideProgressBar: true, isLoading: false, autoClose: 3000, closeOnClick: true });
            if (res.status === 200) {
                setImportingKeyPair(false);
                handleCloseImportKeyPair();
            }
        }).catch((err) => {
            setImportingKeyPair(false);
            console.error(err.response.status);
        }).finally(() => {
            getKeyPairs();
        });
    }

    const onDeleteKeyPair = async (name) => {
        if (window.confirm('This Keypair will be permanently deleted')) {
            const toastId = toast.loading("Deleting Key Pair please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/keypairs/${name}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Keypair successfully deleted", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getKeyPairs();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    const getKeyPairs = async () => {
        setKeyPairs([]);
        setLoadingKeyPairs(true);
        await axios.get(`${getHostNameServer()}/keypairs`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                setLoadingKeyPairs(false);
                setKeyPairs(res.data.keypairs);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <h3 className="float-start">Key Pairs</h3>
                </Col>
                <Col>
                    <Button variant="outline-secondary" onClick={handleShowCreateKeyPair} size="sm" className="float-end mb-3">
                        Create Key Pair
                    </Button>{' '}
                    <Button variant="outline-secondary" onClick={handleShowImportKeyPair} size="sm" className="float-end mb-3">
                        Import Key
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm" id="table-instances" style={{ fontSize: '14px' }}>
                <thead>
                    <tr>
                        <th>...</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Fingerprint</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {keyPairs.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={13}>
                                {loadingKeyPairs ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any keyPairs</p>}
                            </td>
                        </tr>
                    )}
                    {keyPairs.map((keypair, _) => {
                        return (
                            <tr key={keypair.keypair.name}>
                                <td><Form.Check></Form.Check></td>
                                <td>{keypair.keypair.name}</td>
                                <td>{keypair.keypair.type == null ? 'ssh' : keypair.keypair.type}</td>
                                <td>{keypair.keypair.fingerprint}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Dropdown.Item href="#" onClick={() => onDeleteKeyPair(keypair.keypair.name)}>Delete Keypair</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={showCreateKeyPair} onHide={handleCloseCreateKeyPair} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Create Key Pair</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onCreateKeyPair}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control name="name" size="sm" placeholder="Enter name" value={createkeyPair.name} onChange={handleCreateKeyPairs} disabled={creatingKeyPair} required />
                        </Form.Group>

                        {/** <Form.Group className="mb-3" controlId="type">
                            <Form.Label>SSH Key Type <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="type" onChange={handleCreateKeyPairs} value={createkeyPair.type} size="sm" required >
                                <option value="">Select Key type</option>
                                <option value="ssh">SSH Key</option>
                            </Form.Select>
                        </Form.Group> */}

                        <Button variant="primary" size="sm" type="submit" disabled={creatingKeyPair}>
                            Create
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleCloseCreateKeyPair} size="sm" type="button" disabled={creatingKeyPair}>
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={showImportKeyPair} onHide={handleCloseImportKeyPair} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Importing Key</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onImportKeyPair}>
                        <Form.Group className="mb-3" controlId="importName">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control name="name" size="sm" placeholder="Enter name" value={importkeyPair.name} onChange={handleImportKeyPairs} disabled={importingKeyPair} required />
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="importType">
                            <Form.Label>SSH Key Type <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="type" value={importkeyPair.type} onChange={handleImportKeyPairs} size="sm" required >
                                <option value="">Select Key type</option>
                                <option value="ssh">SSH Key</option>
                            </Form.Select>
                        </Form.Group> */}

                        <Form.Group className="mb-3" controlId="publicKey">
                            <Form.Label>SSH Key <span className="text-danger">*</span></Form.Label>
                            <Form.Control as="textarea" name="public_key" rows={5} placeholder="Paste ssh key here" value={importkeyPair.public_key} onChange={handleImportKeyPairs} disabled={importingKeyPair} required />
                            <Form.Text className="text-muted">
                                We'll never share your key with anyone else.
                            </Form.Text>
                        </Form.Group>
                        <Button variant="primary" size="sm" type="submit" disabled={importingKeyPair}>
                            Import
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleCloseImportKeyPair} size="sm" type="button" disabled={importingKeyPair}>
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );
}
export default Keypairs;
