import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/public/layout';
import Overview from './components/protected/overview';
import Instances from './components/protected/compute/instances';
import keycloakAuth from './services/keycloak';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { Spinner } from 'react-bootstrap';
import Notfound from './components/public/errors/not-found';
import Welcome from './components/public/welcome/welcome';
import Keypairs from './components/protected/compute/keypairs';
import Images from './components/protected/compute/images';
import Volumes from './components/protected/volumes/volumes';
import Snapshots from './components/protected/volumes/snapshots';
import Groups from './components/protected/volumes/groups';
import Networks from './components/protected/network/networks';
import Floatingips from './components/protected/network/floating-ips';
import Securitygroups from './components/protected/network/security-groups';
import Init from './components/public/init';
import Rules from './components/protected/network/rules';
import Manage from './components/protected/network/routers/manage';
import Routers from './components/protected/network/routers/routers';
import NetworkDetails from './components/protected/network/network-details';
import StatusProvider from './contexts/status-context';
import InstanceDetails from './components/protected/compute/instances-detail';
import Instancetype from './components/protected/compute/instance-types';

const AuthRoutes = () => {

  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', minHeight: '800px', alignItems: 'center' }}>
        <h2 style={{ textAlign: 'center' }}>
          <Spinner animation="border" variant="primary" />{" "}
          Loading...
        </h2>
      </div>
    );
  }

  return (
    <Routes>
      <>
        {keycloak.authenticated && (
          <>
            <Route path="/" element={<Layout />} >
              <Route index element={<Init />} />
              <Route path="overview" element={<Overview />} />
              <Route path="compute/instances" element={<Instances />} />
              <Route path="compute/instance-types" element={<Instancetype />} />
              <Route path="compute/instances/:id/detail" element={<InstanceDetails />} />
              <Route path="compute/keypairs" element={<Keypairs />} />
              <Route path="compute/images" element={<Images />} />

              <Route path="volume/volumes" element={<Volumes />} />
              <Route path="volume/snapshots" element={<Snapshots />} />
              <Route path="volume/groups" element={<Groups />} />

              <Route path="network/networks" element={<Networks />} />
              <Route path="network/networks/:id/detail" element={<NetworkDetails />} />
              <Route path="network/routers" element={<Routers />} />
              <Route path="network/routers/:id" element={<Manage />} />
              <Route path="network/security-groups" element={<Securitygroups />} />
              <Route path="network/security-groups/:security_group_id/rules" element={<Rules />} />
              <Route path="network/floating-ips" element={<Floatingips />} />

            </Route>
            <Route path="*" element={<Notfound />} />
          </>
        )}
      </>
      <>
        <Route path="/" element={<Welcome />} >
          <Route path="*" element={<Notfound />} />
        </Route>
      </>
    </Routes>
  );

}

function App() {

  return (
    <div>
      <ReactKeycloakProvider authClient={keycloakAuth}>
        <StatusProvider>
          <AuthRoutes />
        </StatusProvider>
      </ReactKeycloakProvider>
    </div>
  );

}

export default App;
