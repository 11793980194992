import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const Welcome = () => {

    const { keycloak, initialized } = useKeycloak();
    const [expand, setExpand] = useState("sm");

    useEffect(() => {
        console.log(initialized);
        setExpand("sm");
    });

    return (
        <>
            <Navbar key={expand} bg="white" expand={expand} className="mb-3">
                <Container fluid>
                    {/* <Navbar.Brand href="#"><strong>IDTOLU Cloud</strong></Navbar.Brand> */}
                    <Navbar.Brand href="#"><img src="/icons/header_logo.png" width="50%" alt="..." /></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link href="#" onClick={() => keycloak.login()} className="text-dark"><strong>Sign In</strong></Nav.Link>
                        <Nav.Link href="#" className="text-dark"><strong>Sign Up</strong></Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <h1 className="text-center text-primary"><strong>Cloud Computing Solution with IDTOLU Cloud</strong></h1>
            <h4 className="text-center"><em>With a lot of powerful features</em></h4>
            <div className="d-flex justify-content-center mt-2">
                <img src="/assets/img/img0.png" width="50%" alt="..." />
            </div>
            {/* <div className="text-center" style={{ display: 'grid', paddingLeft: '100px', paddingRight: '100px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '32px'}}>
                <img className="text-center" style={{borderRadius: '8px', width: '50%', height: 'auto'}} src="/icons/ubuntu.png" alt="..." />
                <img style={{borderRadius: '8px', width: '50%', height: 'auto'}} src="/icons/ansible.png" alt="..." />
                <img style={{borderRadius: '8px', width: '50%', height: 'auto'}} src="/icons/kubernetes.png" alt="..." />
                <img style={{borderRadius: '8px', width: '50%', height: 'auto'}} src="/icons/debian.png" alt="..." />
                <img style={{borderRadius: '8px', width: '50%', height: 'auto'}} src="/assets/img/img0.png" alt="..." />
                <img style={{borderRadius: '8px', width: '50%', height: 'auto'}} src="/assets/img/img0.png" alt="..." />
            </div> */}
            {/* <Container className="mt-5">
                <Row className="row-cols-1 row-cols-md-2 g-2">
                    <Col>
                        <div className="card">
                            <div className="row g-0">
                                <div className="col-md-4 d-flex align-items-center">
                                    <img src="assets/img/compute.png" className="img-fluid rounded-start p-3" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Compute</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <div className="row g-0">
                                <div className="col-md-4 d-flex align-items-center">
                                    <img src="assets/img/kubernetes.png" className="img-fluid rounded-start p-3" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Kubernetes</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container> */}
            <footer className="footer mt-5">
                <div className="copyright">
                    &copy; Copyright <strong><span>IDTOLU Lab</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Adapted by <a href="#">IDTOLU Lab</a>
                </div>
            </footer>
        </>
    );

}
export default Welcome;