import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
/**import { DataTable } from "simple-datatables";*/
import "simple-datatables/dist/css/style.css";
import { getHostNameServer } from "../../../services/helpers/config";
import { Button, Col, Dropdown, DropdownButton, Form, Offcanvas, Row, Spinner, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const Securitygroups = () => {

    const { keycloak, initialized } = useKeycloak();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /**const [data, setData] = useState(null);*/

    /**
     * Security Groups States
     */
    const [securityGroups, setSecurityGroups] = useState([]);
    const [securityGroup, setSecurityGroup] = useState({ floating_network_id: '', description: '' });
    const [loadingSecurityGroups, setLoadingSecurityGroups] = useState(false);
    const [creatingSecurityGroup, setCreatingSecurityGroup] = useState(false);

    useEffect(() => {
        console.log(initialized);
        getSecurityGroups();
    }, []);

    /**useEffect(() => {
        const dataTable = new DataTable('#table-instances', {
            fixedHeight: true,
            searchable: true,
            paging: true,
            //sortable: true
            //footer: true,
            //data: bodyTable
        });
        setData(dataTable);
        return () => dataTable.destroy();
    }, [setData]);*/

    const handleSecurityGroups = (event) => {
        const fields = { name: securityGroup.name, description: securityGroup.description };
        fields[event.target.name] = event.target.value;
        setSecurityGroup(fields);
    }

    const onCreateSecurityGroup = async (e) => {
        e.preventDefault();
        setCreatingSecurityGroup(true);
        const toastId = toast.loading("Creating security group please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/security-groups`, { security_group: securityGroup }, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            toast.update(toastId, { render: "Security Group successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            setCreatingSecurityGroup(false);
            handleClose();
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getSecurityGroups();
        });
    }

    const onDeleteSecurityGroup = async (security_group_id) => {
        if (window.confirm('This security group will be permanently deleted')) {
            const toastId = toast.loading("Deleting security group please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/security-groups/${security_group_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Security group successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getSecurityGroups();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    const getSecurityGroups = async () => {
        setLoadingSecurityGroups(true);
        await axios.get(`${getHostNameServer()}/security-groups`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.security_groups.length > 0) {
                /**console.log(res.data);*/
                setSecurityGroups(res.data.security_groups);
            }
            setLoadingSecurityGroups(false);
        }).catch(err => {
            toast.error(`We are having problems: ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <h3>Security Groups</h3>
                </Col>
                <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3">
                        Create security group
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm" id="table-instances" style={{ fontSize: '14px' }}>
                <thead>
                    <tr>
                        <th>...</th>
                        <th>Name</th>
                        <th>Security Group ID</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {securityGroups.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={5}>
                                {loadingSecurityGroups ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any security groups</p>}
                                <Button variant="outline-secondary" onClick={handleShow} size="sm" className="mb-3" disabled={loadingSecurityGroups}>
                                    Create security group
                                </Button>
                            </td>
                        </tr>
                    )}
                    {securityGroups.map((security_group, _) => {
                        return (
                            <tr key={security_group.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{security_group.name}</td>
                                <td>{security_group.id}</td>
                                <td>{security_group.description}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Link className="dropdown-item" to={`/network/security-groups/${security_group.id}/rules`}>Manage Rules</Link>
                                        {/* <Dropdown.Item href="#">Edit</Dropdown.Item> */}
                                        <Dropdown.Item href="#" onClick={() => onDeleteSecurityGroup(security_group.id)}>Delete security group</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>New security group</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h4><strong>Description:</strong></h4>
                    <span>Security groups are sets of IP filter rules that are applied to network interfaces of a VM. After the security group is created, you can add rules to the security group.</span>
                    <Form onSubmit={onCreateSecurityGroup}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" name="name" size="sm" placeholder="Enter name" onChange={handleSecurityGroups} pattern="[a-zA-Z_]*" maxLength={20} disabled={creatingSecurityGroup} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" name="description" size="sm" rows={1} placeholder="Description" onChange={handleSecurityGroups} disabled={creatingSecurityGroup} />
                        </Form.Group>
                        <Button variant="primary" size="sm" type="submit" disabled={creatingSecurityGroup}>
                            Create security group
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button" disabled={creatingSecurityGroup}>
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );

}
export default Securitygroups;