import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { Button, Col, Offcanvas, Row, Table, Form, DropdownButton, Dropdown, Spinner } from "react-bootstrap";
import { getHostNameServer } from "../../../services/helpers/config";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const Networks = () => {

    const { keycloak, initialized } = useKeycloak();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [enableAdminStateUp, setEnableAdminStateUp] = useState(true);
    const [createSubnet, setCreateSubnet] = useState(true);
    const [enableShared, setEnableShared] = useState(false);
    const [enableGateway, setEnableGateway] = useState(true);

    const [networks, setNetworks] = useState([]);
    const [network, setNetwork] = useState({ name: '', floating_network_id: '', availability_zone_hints: [], mtu: 1400 });
    const [subNetwork, setSubNetwork] = useState({ name: '', floating_network_id: '', cidr: '', ip_version: '4', gateway_ip: '' });
    const [launchingNetwork, setLaunchingNetwork] = useState(false);
    const [loadingNetworks, setLoadingNetworks] = useState(false);

    useEffect(() => {
        console.log(initialized);
        getNetworks();
    }, []);

    const handleChangeCreateSubnet = (event) => {
        if (event.target.name === 'create_subnet') {
            setCreateSubnet(event.target.checked);
        }
    }

    const handleChangeEnableShared = (event) => {
        if (event.target.name === 'shared') {
            setEnableShared(event.target.checked);
        }
    }

    const handleChangeEnableGateway = (event) => {
        if (event.target.name === 'enableGateway') {
            setEnableGateway(event.target.checked);
        }
    }

    const handleChangeNetwork = (event) => {
        const fields = { name: network.name, admin_state_up: enableAdminStateUp, availability_zone_hints: network.availability_zone_hints, mtu: network.mtu };
        fields[event.target.name] = event.target.value;
        setNetwork(fields);
        ///console.log(fields);
    }

    const handleChangeSubNetwork = (event) => {
        const fields = { name: subNetwork.name, cidr: subNetwork.cidr, ip_version: subNetwork.ip_version, gateway_ip: subNetwork.gateway_ip };
        fields[event.target.name] = event.target.value;
        setSubNetwork(fields);
        ///console.log(fields);
    }

    const onSubmitNetwork = async (e) => {
        e.preventDefault();
        setLaunchingNetwork(true);
        network.shared = enableShared;
        network.availability_zone_hints = network.availability_zone_hints.length > 0 ? network.availability_zone_hints.split('\n') : [];

        const toastId = toast.loading("Creating Network please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/networks-with-subnet`, { network: network, subnet: subNetwork, createSubnet: createSubnet }, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            toast.update(toastId, { render: "Network successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            ///console.log(res.data);
            handleClose();
        }).catch((err) => {
            ///console.error(err.response.status);
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getNetworks();
        });
    }

    const onDeleteNetwork = async (network_id) => {
        if (window.confirm('This Network will be permanently deleted')) {
            const toastId = toast.loading("Deleting Network please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/networks/${network_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Network successfully deleted", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}, Maybe the network is in use`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getNetworks();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    const getSubnetAssociated = async () => {
        await axios.get(`${getHostNameServer()}/networks`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            console.log(res.data.networks);
            if (res.status === 200) {
                setNetworks(res.data.networks);
            }
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    const getNetworks = async () => {
        setLoadingNetworks(true);
        await axios.get(`${getHostNameServer()}/networks`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.status === 200) {
                setNetworks(res.data.networks);
            }
            setLoadingNetworks(false);
        }).catch((err) => {
            console.error(err.response.status);
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <h3 className="floet-start">Networks</h3>
                </Col>
                <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3" disabled={networks.length >= 1 ? true : false}>
                        Create Network
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Network Name</th>
                        <th>Subnet Associated</th>
                        <th>Shared</th>
                        <th>External</th>
                        <th>Status</th>
                        <th>Admin State</th>
                        <th>Availability Zone</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {networks.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={9}>
                                {loadingNetworks ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any networks</p>}
                                <Button variant="outline-secondary" onClick={handleShow} size="sm" className="mb-3" disabled={loadingNetworks}>
                                    Create network
                                </Button>
                            </td>
                        </tr>
                    )}
                    {networks.map((network, _) => {
                        return (
                            <tr key={network.id}>
                                <td><Form.Check></Form.Check></td>
                                <td><Link to={`/network/networks/${network.id}/detail`}>{network.name}</Link></td>
                                <td>{network.subnets.join(', ')}</td>
                                <td>{network.shared}</td>
                                <td>{network['router:external'] ? 'YES' : 'NO'}</td>
                                <td>{network.status}</td>
                                <td>{network.admin_state_up ? 'UP' : 'DOWN'}</td>
                                <td>{network.availability_zones.length > 0 ? network.availability_zones.join(', ') : '-'}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Dropdown.Item href="#" onClick={() => onDeleteNetwork(network.id)}>Delete Network</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><strong>Create Network</strong></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onSubmitNetwork}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" name="name" size="sm" placeholder="Enter name" value={network.name} onChange={handleChangeNetwork} pattern="[a-zA-Z_]*" maxLength={20} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="admin_state_up">
                            <Form.Check type="checkbox" name="admin_state_up" defaultValue={enableAdminStateUp} onChange={handleChangeNetwork} defaultChecked={enableAdminStateUp} label="Enable Admin State" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="create_subnet">
                            <Form.Check type="checkbox" name="create_subnet" onChange={handleChangeCreateSubnet} defaultChecked={createSubnet} label="Create Subnet" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="shared">
                            <Form.Check type="checkbox" name="shared" defaultValue={enableShared} defaultChecked={enableShared} onChange={handleChangeEnableShared} label="Shared" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="availability_zone_hints">
                            <Form.Label>Availability Zone Hints</Form.Label>
                            <Form.Control as="textarea" name="availability_zone_hints" onChange={handleChangeNetwork} rows={4} placeholder="" value={network.availability_zone_hints} />
                            <Form.Text className="text-muted">
                                Type each DNS one below the other
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mtu">
                            <Form.Label>MTU <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="number" name="mtu" size="sm" minLength={68} value={network.mtu} onChange={handleChangeNetwork} placeholder="MTU" required />
                            <Form.Text className="text-muted">
                                Minimum value is 68 for IPv4, and 1280 for IPv6.
                            </Form.Text>
                        </Form.Group>

                        {createSubnet && (
                            <>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Subnet Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" name="name" value={subNetwork.name} size="sm" placeholder="Enter name" onChange={handleChangeSubNetwork} pattern="[a-zA-Z_]*" maxLength={20} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Label>Network Address <span className="text-danger">*</span></Form.Label>
                                    <Form.Control name="cidr" value={subNetwork.cidr} size="sm" placeholder="Network Address" onChange={handleChangeSubNetwork} pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/(?:[12]?[0-9]|3[0-2])$" required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="availability_zone">
                                    <Form.Label>IP Version <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="ip_version" value={subNetwork.ip_version} onChange={handleChangeSubNetwork} size="sm" required >
                                        <option value="4">IPv4</option>
                                        <option value="6">IPv6</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Gateway IP <span className="text-danger">*</span></Form.Label>
                                    <Form.Control name="gateway_ip" value={subNetwork.gateway_ip} size="sm" placeholder="Gateway IP" onChange={handleChangeSubNetwork} required pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" name="enableGateway" defaultChecked={enableGateway} label="Enable Gateway" onChange={handleChangeEnableGateway} />
                                </Form.Group>
                            </>
                        )}

                        <Button variant="primary" size="sm" type="submit" disabled={launchingNetwork}>
                            Create
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );

}
export default Networks;