import { createContext, useState } from "react";

export const StatusContext = createContext(false);

const StatusProvider = ({children}) => {

    const [status, setStatus] = useState({verified: false, passed: false});

    return (
        <StatusContext.Provider value={[status, setStatus]}>
            {children}
        </StatusContext.Provider>
    );

}
export default StatusProvider;