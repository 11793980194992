import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Offcanvas, Row, Spinner, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../../services/helpers/config";

const Interfaces = (props) => {

    const { keycloak, initialized } = useKeycloak();
    let id = props.id;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /**
     * Interfaces States
     */
    const [subnets, setSubnets] = useState([]);
    ///const [interfaces, setInterfaces] = useState({});
    const [interFace, setInterface] = useState({ subnet_id: ''/*, router_id: ''*/ });
    const [loadingInterfaces, setLoadingInterfaces] = useState(false);

    const [ports, setPorts] = useState([]);

    useEffect(() => {
        console.log(initialized);
        ///getInterfaces();
        getSubnets();
        getPorts();
    }, []);

    const handleInterface = (event) => {
        const fields = { subnet_id: interFace.subnet_id/*, router_id: interFace.router_id*/ };
        fields[event.target.name] = event.target.value;
        setInterface(fields);
        /**console.log(fields);*/
    }

    const onAddInterface = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Adding Interface please wait", { autoClose: 5000 });
        await axios.put(`${getHostNameServer()}/routers/${id}/add-router-interface`, interFace, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            toast.update(toastId, { render: "Interface successfully added", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleClose();
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getPorts();
            ///getInterfaces();
        });
    }

    const onRemoveInterface = async (subnet_id) => {
        if (window.confirm('This Interface will be permanently removed')) {
            const toastId = toast.loading("Removing Interface please wait", { autoClose: 5000 });
            await axios.put(`${getHostNameServer()}/routers/${id}/remove-router-interface`, { subnet_id: subnet_id }, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Interface successfully removed", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getPorts();
                ///getInterfaces();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    /**const getInterfaces = async () => {
        await axios.get(`${getHostNameServer()}/routers/${id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            console.log(res.data);
            if (res.data.router) {
                setInterfaces(res.data.router);
            }
        }).catch((err) => {
            toast.error(`We are having problems`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }*/

    const getPorts = async () => {
        setLoadingInterfaces(true);
        await axios.get(`${getHostNameServer()}/ports/${id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            ///console.log(res.data.ports);
            if (res.data.ports) {
                setPorts(res.data.ports);
                setLoadingInterfaces(false);
            }
        }).catch((err) => {
            toast.error(`We are having problems`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    const getFixedIps = (fixed_ips) => {
        let ipAddresses = fixed_ips.map(obj => obj.ip_address);
        return ipAddresses.join(',');
    }

    const getSubnets = async () => { // obtain all keypairs registered in the server
        await axios.get(`${getHostNameServer()}/subnets`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            ///console.log(res.data);
            if (res.data.subnets.length > 0) {
                setSubnets(res.data.subnets);
            }
        }).catch((err) => {
            toast.error(`We are having problems`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    return (
        <>
            {/* <h5>Instances</h5> */}
            <Row>
                <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3">
                        Add Interface
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm" id="table-instances" style={{ fontSize: '14px' }}>
                <thead>
                    <tr>
                        <th>...</th>
                        <th>Name</th>
                        <th>Fixed IPs</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Admin State</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {ports.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={13}>
                                {loadingInterfaces ?
                                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: '50px', alignItems: 'center' }}>
                                        <h5 className="text-secondary" style={{ textAlign: 'center' }}>
                                            <Spinner animation="grow" size="sm" variant="info" />{" "}
                                            Loading...
                                        </h5>
                                    </div>
                                    : <p>You do not have any interfaces</p>}
                            </td>
                        </tr>
                    )}
                    {ports.map((port, _) => {
                        return (
                            <tr key={port.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{port.name ? port.name : port.id}</td>
                                <td>{getFixedIps(port.fixed_ips)}</td>
                                <td>{port.status}</td>
                                <td>{""}</td>
                                <td>{port.admin_state_up ? 'UP' : 'DOWN'}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Dropdown.Item href="#" onClick={() => onRemoveInterface(port.fixed_ips[0].subnet_id)}>Delete Interface</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add Interface</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <strong>Description: </strong>
                    <p style={{ fontSize: '14px' }}>You can connect a specified subnet to the router. If you don't specify an IP address here, the gateway's IP address of the selected subnet will be used as the IP address of the newly created interface of the router. If the gateway's IP address is in use, you must use a different address which belongs to the selected subnet.</p>
                    <Form onSubmit={onAddInterface}>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Subnet <span className="text-danger">*</span></Form.Label>
                            <Form.Select size="sm" name="subnet_id" value={interFace.subnet_id} onChange={handleInterface} required>
                                <option value="">Select</option>
                                {subnets.map((subnet, _) => {
                                    return (<option key={subnet.id} value={subnet.id}>{subnet.name}</option>);
                                })}
                            </Form.Select>
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="remote_ip_prefix">
                            <Form.Label>IP Address (Optional)</Form.Label>
                            <Form.Control size="sm" name="router_id" value={interFace.router_id} onChange={handleInterface} />
                        </Form.Group> */}

                        <Button variant="primary" size="sm" type="submit">
                            Add
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );
}
export default Interfaces