import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Offcanvas, Row, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";
import { useParams } from "react-router-dom";

const NetworkDetails = () => {

    const { keycloak, initialized } = useKeycloak();
    let { id } = useParams();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /**
     * Subnets States
     */
    const [subnets, setSubnets] = useState([]);
    const [subnet, setSubnet] = useState({ network_id: id, name: '', cidr: '', ip_version: '4', gateway_ip: '' });
    ///const [enableGateway, setEnableGateway] = useState(true);

    ///const [ports, setPorts] = useState([]);

    useEffect(() => {
        console.log(initialized);
        getSubnets();
        ///getPorts();
    }, []);

    const handleChangeAddSubnet = (event) => {
        const fields = { network_id: subnet.network_id, name: subnet.name, cidr: subnet.cidr, ip_version: subnet.ip_version, gateway_ip: subnet.gateway_ip };
        fields[event.target.name] = event.target.value;
        setSubnet(fields);
        console.log(fields);
    }

    /**const handleChangeEnableGateway = (event) => {
        if (event.target.name === 'enableGateway') {
            setEnableGateway(event.target.checked);
        }
    }*/

    const onCreateSubnet = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Creating Subnet please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/subnets`, { subnet: subnet }, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            toast.update(toastId, { render: "Subnet successfully created", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            handleClose();
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            getSubnets();
        });
    }

    const onDeleteSubnet = async (subnet_id) => {
        if (window.confirm('This Subnet will be permanently deleted')) {
            const toastId = toast.loading("Deleting Subnet please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/subnets/${subnet_id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
                toast.update(toastId, { render: "Subnet successfully removed", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
            }).finally(() => {
                getSubnets();
            });
        } else {
            toast.info('Operation canceled by user', { type: "info", closeOnClick: true, hideProgressBar: true, autoClose: true, isLoading: false });
        }
    }

    /**const getPorts = async () => {
        await axios.get(`${getHostNameServer()}/ports/${id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            console.log(res.data.ports);
            if (res.data.ports) {
                setPorts(res.data.ports);
            }
        }).catch((err) => {
            toast.error(`We are having problems`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }*/

    const getSubnets = async () => { // obtain all keypairs registered in the server
        await axios.get(`${getHostNameServer()}/${id}/subnets`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            ///console.log(res.data);
            if (res.data.subnets.length > 0) {
                setSubnets(res.data.subnets);
            }
        }).catch((err) => {
            toast.error(`We are having problems ${err.response.statusText}`, { closeOnClick: true, hideProgressBar: true, autoClose: false, isLoading: false });
        }).finally(() => {
            console.log('Finished');
        });
    }

    return (
        <>
            {/* <h5>Instances</h5> */}
            <Row>
                <Col>
                    <Button variant="outline-primary" onClick={handleShow} size="sm" className="float-end mb-3">
                        Create Subnet
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm" id="table-instances" style={{ fontSize: '14px' }}>
                <thead>
                    <tr>
                        <th>...</th>
                        <th>Name</th>
                        <th>Network Address</th>
                        <th>IP Version</th>
                        <th>Gateway IP</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {subnets.map((subnet, _) => {
                        return (
                            <tr key={subnet.id}>
                                <td><Form.Check></Form.Check></td>
                                <td>{subnet.name ? subnet.name : subnet.id}</td>
                                <td>{subnet.cidr}</td>
                                <td>IPv{subnet.ip_version}</td>
                                <td>{subnet.gateway_ip}</td>
                                <td>
                                    <DropdownButton variant="outline-secondary" aria-haspopup="true" flip={true} id="dropdown-basic-button" size="sm" title="Actions">
                                        <Dropdown.Item href="#" onClick={() => onDeleteSubnet(subnet.id)}>Delete Subnet</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Create Subnet</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={onCreateSubnet}>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Subnet Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" name="name" value={subnet.name} size="sm" placeholder="Enter name" onChange={handleChangeAddSubnet} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Label>Network Address <span className="text-danger">*</span></Form.Label>
                            <Form.Control name="cidr" value={subnet.cidr} size="sm" placeholder="Network Address" onChange={handleChangeAddSubnet} pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/(?:[12]?[0-9]|3[0-2])$" required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="availability_zone">
                            <Form.Label>IP Version <span className="text-danger">*</span></Form.Label>
                            <Form.Select name="ip_version" value={subnet.ip_version} onChange={handleChangeAddSubnet} size="sm" required >
                                <option value="4">IPv4</option>
                                <option value="6">IPv6</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Gateway IP <span className="text-danger">*</span></Form.Label>
                            <Form.Control name="gateway_ip" value={subnet.gateway_ip} size="sm" placeholder="Gateway IP" onChange={handleChangeAddSubnet} pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$" required  />
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" name="enableGateway" defaultChecked={enableGateway} label="Enable Gateway" onChange={handleChangeEnableGateway} />
                        </Form.Group> */}

                        <Button variant="primary" size="sm" type="submit">
                            Create Subnet
                        </Button>{' '}
                        <Button variant="outline-secondary" onClick={handleClose} size="sm" type="button">
                            Cancel
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </>
    );
}
export default NetworkDetails;